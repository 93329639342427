// Define CSS custom properties for status colors
:root {
  // Success Colors
  --status-success-color: #15602B;
  --status-success-bg: rgba(29, 177, 72, 0.10);
  --status-success-border: rgba(29, 177, 72, 0.10);

  // Warning Colors
  --status-warning-color: #655006;
  --status-warning-bg: rgba(241, 194, 27, 0.15);
  --status-warning-border: rgba(241, 194, 27, 0.15);

  // Error Colors
  --status-error-color: #821218;
  --status-error-bg: rgba(218, 30, 40, 0.10);
  --status-error-border: rgba(218, 30, 40, 0.10);

  // Info Colors
  --status-info-color: #0E50B3;
  --status-info-bg: rgba(25, 153, 255, 0.10);
  --status-info-border: rgba(25, 153, 255, 0.10);

  // Neutral Colors
  --status-neutral-color: #4D4D4D;
  --status-neutral-bg: rgba(77, 77, 77, 0.10);
  --status-neutral-border: rgba(77, 77, 77, 0.10);
}

// Dark mode colors
[data-theme="dark"] {
  --status-success-color: #1DB148;
  --status-success-bg: rgba(29, 177, 72, 0.30);
  --status-success-border: rgba(29, 177, 72, 0.30);

  --status-warning-color: #FEE278;
  --status-warning-bg: rgba(241, 194, 27, 0.35);
  --status-warning-border: rgba(241, 194, 27, 0.35);

  --status-error-color: #F2A1A5;
  --status-error-bg: rgba(218, 30, 40, 0.30);
  --status-error-border: rgba(218, 30, 40, 0.30);

  --status-info-color: #9CC0F7;
  --status-info-bg: rgba(25, 153, 255, 0.30);
  --status-info-border: rgba(25, 153, 255, 0.30);

  --status-neutral-color: #B3B3B3;
  --status-neutral-bg: rgba(77, 77, 77, 0.30);
  --status-neutral-border: rgba(77, 77, 77, 0.30);
}

// SCSS variables for easy reference in other SCSS files
$status-types: (
  'success',
  'warning',
  'error',
  'info',
  'neutral'
);
