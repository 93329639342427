@import "app/assets/css/variables.scss";

.variables-view {
  &.edit-mode {
    padding-bottom: 0px;
  }
  
  .card-header {
    height: 46px;
  }
  .variable-container {
    display: grid;
    grid-template-columns: 10fr 10fr 1fr;
    column-gap: $spacing-05;
    
    button {
      margin-top: 10px;
    }

    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
    }
  }

  .view-mode-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;

    .view-mode-data {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;

      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: $spacing-04;
  }
}