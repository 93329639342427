@import "app/assets/css/variables.scss";

.gooten-calendar {
  width: 100%;

  > * {
    position: relative;
    width: 100%;
  }

  .react-datepicker__input-container input:focus-visible {
    outline: none !important; /* Removes the outline */
  }

  label {
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;
    margin-bottom: $spacing-02;

    &.disabled {
      color: #B5B5B5;
    }
  }

  input {
    width: 100%;
    color: #2E2E2E;
    font-size: 16px;
    line-height: 24px;
    border-radius: $spacing-03;
    background-color: #FCFCFC  !important;
    border: 1px solid #E2E2E2 !important;
    padding: $spacing-03 $spacing-05;
    
    &::placeholder { 
      font-size: 16px; 
      color: #878787;  
      opacity: 1; 
    }

    &:not([readonly]):not(:disabled):focus-visible {
      background-color: red;
      border: 1px solid transparent;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    &:not([readonly]):not(:disabled):hover {
      background-color: #F2F2F2;
      box-shadow: 0 0 0 1px #E2E2E2;
    }

    &:disabled {
      background-color: #E2E2E2;
      color: #B5B5B5;
    }

    &:not([readonly]):not(:disabled).has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
      border: 1px solid transparent !important;
    }
  }

  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #AF1820;
  }

  .question-icon {
    position: absolute;
    top: 21px;
    right: -9px;
    color: #7c7c7c;
    cursor: pointer;
    font-size: 16px;
  }
}