@import "app/assets/css/variables.scss";

.merchant-settings-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-06;

  .card-body {
    height: 100px;
  }

  .settings-holder {

    .settings-sub-section {
      
    }

    .settings-sub-section-title {
      font-size: 16px;
      font-weight: 600;
      margin-top: 15px;
    }

    .settings-item-bool {
      display: flex;
      flex-direction: row;
      padding: 7px 0;

      .settings-item-title {
        padding-left: 10px;
      }
    }

    .settings-item-dropdown {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 0;
      width: 25% !important;
    }

    .settings-item-input {
      display: flex;
      flex-direction: row;
      align-items: baseline;

      .settings-item-input-child {
        width: 30%;
        margin-left: 15px;
      }
    }

    .settings-item-integer {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      justify-content: space-between;

      .settings-item-integer-child {}
    }
  }
}