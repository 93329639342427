@import "app/assets/css/variables.scss";

.shipping-mapping-view {
  .shipping-mapping-card {
    .action-icons {
      text-align: center;
    }

    .shipping-mapping-options {
      display: flex;
      flex-direction: row;
      gap: $spacing-02;
    }

    .no-data {
      text-align: center;
      padding: 50px !important;

      svg {
        margin: -5px 3px 0 0;
      }
    }
  }

  .gooten-modal .gooten-modal-content {
    width: 400px;
  }
}