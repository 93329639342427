.meta-details-card {
  .card-header {
    height: 39px;
  }
  
  .data-value {
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;
  }

  .meta-property {
    display: flex;
    flex-direction: column;

    // add margin to all but the last child
    &:not(:last-child) {
      margin-bottom: 10px;
    }

    .data-key {
      color: #595959;
    }

    .data-value {
      color: rgb(33, 37, 41);
    }
  }

  .pencil-icon {
    cursor: pointer;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    
    .btn-primary {
      margin-left: 8px;
    }
  }
}