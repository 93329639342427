@import "app/assets/css/variables.scss";

.gooten-pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  margin-top: $spacing-05;
  
  .showing-count {
    color: #878787;
  }

  .pagination {
    margin-bottom: 0;

    .pagination-arrow {

      &.page-item > .page-link {
        font-size: 20px !important;
      }
    }
  }

  .page-item.active > .page-link {
    color: #171717;
    background-color: transparent;
    border-radius: 0px;
    border-bottom: 1px solid #171717;
  }

  .page-item.active:hover > .page-link {
    color: #171717;
    background-color: #E0E3E6;
    border-radius: $spacing-02 $spacing-02 0px 0px;
    border-bottom: 1px solid #171717;
    cursor: pointer;
  }

  .page-item:hover > .page-link {
    color: #878787;
    background-color: #E0E3E6;
    border-radius: $spacing-02;
    border: none;
  }

  .page-item > .page-link {
    font-size: 14px;
    color: #878787;
    background-color: transparent;
    padding: $spacing-01 $spacing-03;
    border-radius: $spacing-02 $spacing-02 0px 0px;
    border: none;

   
  }
}
