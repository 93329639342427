.forgot-password {
  max-width: 500px;
  margin: 200px auto;
  padding: 30px;
  

  .title-text {
    font-size: 32px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.5px;
    padding-bottom: 0px;
  }

  .submit-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}