@import "app/assets/css/variables.scss";

.selected-products {
  margin-top: $spacing-04;
  
  .product-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: $spacing-03;
    width: 100%;
  }

  .category-container {
    width: 100%;
    padding-top: 25px;

    .category-title {
      font-size: 20px;
      padding-bottom: 5px;
    }

    &:first-child {
      padding-top: 0px;
    }
  }
}