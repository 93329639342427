$spacing-00: 1px;
$spacing-01: 2px;
$spacing-02: 4px;
$spacing-03: 8px;
$spacing-04: 12px;
$spacing-05: 16px;
$spacing-06: 24px;
$spacing-07: 32px;
$spacing-08: 40px;
$spacing-09: 48px;
$spacing-10: 64px;
$spacing-11: 80px;
$spacing-12: 96px;
$spacing-13: 160px;