@import "app/assets/css/variables.scss";

.vendor-facilities-view {
  .vendor-facilities-card {
    .card-header {
      height: 53px;
    }

    .table-body {
      .facility-status {
        .status-chip {
          margin: 0 auto;
        }
      }

      .facility-options {
        text-align: center;
      }

      .no-facility-data {
        text-align: center;
        padding: 50px !important;

        svg {
          margin: -6px 3px 0 0;
        }
      }
    }
  }
}