@import "app/assets/css/variables.scss";

.summary-view {
  .large-screens {
    display: block;
  }

  .summary-cards {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;

    .search-status-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-05;

      .search-bar {
        max-width: 400px;
        width: 100%;
      }

      .status-filter {
        max-width: 235px;
      }
    }

    .no-matching-search-results {
      width: 100%;
      text-align: center;
      padding: 20px 0;
    }
  }

  .small-screens {
    display: none;
  }

  .data-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
    }
  }

  @media (max-width: 992px) {
    .large-screens {
      display: none;
    }
    .small-screens {
      display: block;
    }
  }
}