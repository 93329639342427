@import "app/assets/css/variables.scss";

.order-details {
  margin: $spacing-05;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: $spacing-06;

  .left-panel { 
    flex: 3;
    min-width: 0;
  }

  .right-panel {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;
    flex: 1;
    min-width: 0;
  }

  @media (max-width: 1250px) {
    flex-direction: column; 

    .left-panel, .right-panel {
      flex: 1 0 100%; 
    }
  }

  .order-details-header {
    margin-bottom: $spacing-05;
  }

  .merchant-name {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    color: #3982EF;
  }

  .order-num-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    flex-wrap: wrap;
    gap: $spacing-04;

    .order-num-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;

      .order-num {
        white-space: nowrap;
      }
    }

    .order-options-group {
      display: flex;
      align-items: center;
      gap: $spacing-04;

      .gooten-button-icon {
        margin-bottom: 3px;
      }
    }
  }

  .extra-details-container {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: $spacing-06;
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;

    .clickable {
      cursor: pointer;
      transition: color 0.2s ease;
      
      &:hover {
        color: #3982EF;
      }
    }
  }

  .data-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
      margin-top: -3px;
    }
  }

  #tabs {
    z-index: 1000;
  }

  .tabs {
    font-size: 0.8125rem;
  }

  // <START> tabs
  $blue : rgb(60, 180, 250);
  $blue-lt : lighten($blue, 20%);
  $text-dark: #636d84;

  .sticky-top {
    position: sticky;
    top: 0px;
    z-index: 999;
  }

  .responsive {
    width: 100%;
    overflow-x: auto;
  }

  .tabs {
    display: table;
    border-collapse: separate;
    table-layout: auto;

    &.tabs-center {
      margin: auto;
    }

    &.tabs-justify {
      width: 100%;
      table-layout: fixed;
    }

    a.tab {
      position: relative;
      display: table-cell;
      transition: all ease .3s;
      padding: 1em 1.6em;
      transform: translate3d(0, 0, 0);
      color: $text-dark;
      white-space: nowrap;
      cursor: pointer;

      &:hover {
        color: $blue;
      }

      &:after {
        transition: all .3s cubic-bezier(1, 0, 0, 1);
        will-change: transform, box-shadow, opacity;
        position: absolute;
        content: '';
        height: 3px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        border-radius: 3px 3px 0px 0px;
        background: $blue-lt;
        box-shadow: 0px 4px 10px 3px rgba($blue, .15);
        opacity: 0;
        transform: scale(0, 1);
      }

      &.active {
        color: $blue;

        &:after {
          opacity: 1;
          transform: scale(1, 1);
        }
      }
    }
  }
}