@import "app/assets/css/variables.scss";

.gooten-link {
  display: inline-block;
  color: #3982EF;

  a {
    color: #3982EF;
    text-decoration: none;

    &:hover {
      text-decoration: underline !important;
      text-decoration-color: #3982EF !important;
      cursor: pointer !important;
    }
  }

  &.size-small {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  &.size-medium {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  &.size-large {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
  }

  &:active {
    color: #0E50B3; 
    text-decoration: none;
  }

  &.disabled {
    a {
      color: #B5B5B5 !important;
      cursor: default;
      text-decoration: none;
    }
  }
}