@import "app/assets/css/variables.scss";

.create-shipment-modal {
  .create-shipment-modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #2E2E2E;
    padding-bottom: $spacing-05;
    border-bottom: 1px solid #E8E8E8;
  }

  .create-shipment-modal-loader {
    height: 239px;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;

    .not-reversible {
      display: flex;
      align-items: center;
      font-size: 16px;
      color: #AF1820;
      padding-right: $spacing-02;

      svg {
        font-size: 18px;
        margin-right: $spacing-02;
      }
    }
  }

  .gooten-modal-content {
    width: 100%;
    max-width: 700px;
  }
}