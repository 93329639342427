.title-section {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-weight: 600;

  .back-button {
    color: var(--color-text-secondary);
    &:hover {
      color: var(--color-text-primary);
    }
  }

  .title-edit-container {
    display: flex;
    align-items: center;
    gap: 8px;

    .title-edit-input {
      min-width: 400px;
      
      input {
        font-size: 24px;
        font-weight: 600;
        padding: 4px 8px;
        height: auto;
      }
    }

    .title-action-button {
      font-size: 16px;
      &.accept-button {
        color: var(--color-success);
      }

      &.cancel-button {
        color: var(--color-error);
      }

      &:hover {
        opacity: 0.8;
      }
    }
  }

  .title-display {
    display: flex;
    align-items: center;
    gap: 8px;

    .title-text {
      font-size: 24px;
      font-weight: 600;
      cursor: pointer;
      color: var(--color-text-primary);

      &:hover {
        color: var(--color-primary);
      }
    }

    .edit-title-button {
      color: var(--color-text-secondary);
      opacity: 0.6;
      font-size: 14px;
      transition: opacity 0.2s ease-in-out;
      &:hover {
        opacity: 1;
      }
    }
  }
}
