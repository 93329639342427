.gooten-breadcrumbs {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  font-weight: 400;
  color: #2E2E2E;

  &.size-small {
    font-size: 14px;
    line-height: 24px;
  }

  &.size-medium {
    font-size: 16px;
    line-height: 26px;
  }

  &.size-large {
    font-size: 18px;
    line-height: 30px;
  }

  .gooten-breadcrumbs-link {
    color: #3982EF;
    text-decoration: none;
    border-bottom: 1px solid transparent;
    transition: border-bottom 0.2s ease-in-out;
    cursor: pointer;

    &:hover {
      border-bottom: 1px solid #3982EF;
    }
  }
}