@import "app/assets/css/variables.scss";

.vendor-history-view {
  .card-header {
    height: 53px;
  }
  
  .history-data-empty {
    text-align: center;
    padding: 50px 0 !important;
  }

  .error-message {
    text-align: center;
    padding: 50px !important;

    svg {
      margin: -6px 3px 0 0;
    }
  }

  .entity-type-dropdown {
    max-width: 250px;
  }
}