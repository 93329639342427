@import "app/assets/css/variables.scss";

.vendor-ftp-inventory-sync {
  .vendor-information {
    display: flex;
    flex-direction: column;

    .data-point {
      &:not(:last-child) {
        margin-bottom: $spacing-04;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;

    .btn-primary {
      margin-left: 8px;
    }
  }
}
