@import "app/assets/css/variables.scss";

.create-template-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-06;
  margin: $spacing-05;

  .create-template-title-card {

    .card-header {
      border-bottom: 0;
      padding-bottom: 0;

      .top-holder {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 32px;
        font-weight: 600;
      }
    }
  }

  .create-template-card {
    height: 100%;

    .template-form {
      display: flex;
      flex-direction: row;

      .template-name {
        margin-right: $spacing-03;
        flex: 1;
      }

      .template-description {
        margin-left: $spacing-03;
        flex: 1;
      }
    }

    .code-snippet-editor {
      margin-top: $spacing-03;
      border: 1px solid #E2E2E2 !important;
    }

    .code-editor-error-message {
      margin-top: $spacing-03;
      font-size: 12px;
      line-height: 20px;
      height: 20px;
      letter-spacing: 0.5px;
      color: #E6474F;
    }
  }
}