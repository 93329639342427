@import "app/assets/css/variables.scss";

.filter-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: $spacing-03;

  .applied-filters-label {
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}