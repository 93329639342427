@import "app/assets/css/variables.scss";
@import "./colors";

.status-chip {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 999px;
  font-weight: 400;
  width: fit-content;
  white-space: nowrap;
  border: 2px solid transparent;

  &.size-slim {
    padding: $spacing-01 $spacing-03;
    line-height: 24px;
    font-size: 14px;
  }

  &.size-small {
    padding: $spacing-01 $spacing-03;
    line-height: 24px;
    font-size: 16px;
  }

  &.size-medium {
    padding: $spacing-02 $spacing-03;
    line-height: 26px;
    font-size: 16px;
  }

  &.clickable {
    cursor: pointer;
  }

  .status-icon-right {
    margin-left: $spacing-02;
    margin-top: -6px;
  }

  .status-icon-left {
    margin-right: $spacing-02;
    margin-top: -6px;
  }

  // Generate status-specific styles
  @each $type in $status-types {
    &.status-#{$type} {
      color: var(--status-#{$type}-color);
      background-color: var(--status-#{$type}-bg);
      border-color: var(--status-#{$type}-border);
    }
  }
}
