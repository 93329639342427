@import "app/assets/css/variables.scss";

.unassigned-items-card {
  &.gooten-card {
    padding: 0px;

    .card-header:not(.collapsed) {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  .card-header {
    align-items: flex-start;
    flex-direction: column;
  }

  .card-body {
    padding: $spacing-05;
  }

  .unassigned-items-header {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-03;
    width: 100%;
    background-color: #171717;
    padding: $spacing-05;
    border-radius: $spacing-05 $spacing-05 0 0;

    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 26px;

    .header-title {
      display: flex;
      align-items: center;
      gap: $spacing-04;
      font-size: 20px;
      font-weight: 700;
      line-height: 32px;
      color: #FFF;
    }

    .item-options {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-02;
    }

    &.collapsed {
      border-radius: $spacing-05;
    }

    &.all-items-canceled {
      background-color: #4E4D4D;
    }
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-04;
  }

  .canceled-items-section {
    margin-top: $spacing-05;
    display: flex;
    flex-direction: column;
    gap: $spacing-04;

    .canceled-items-top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $spacing-03;
      margin-bottom: $spacing-02;
      border-bottom: 1px solid #ededed;

      .canceled-items-title {
        display: flex;
        align-items: center;
        gap: $spacing-03;
        font-size: 16px;
        font-weight: 600;
        color: #2e2e2e;
  
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}
