@import "app/assets/css/variables.scss";

.date-filter {
  .gooten-modal-content {
    width: 100%;
    max-width: 380px;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .date-filter-tabs {
    display: flex;
    border-bottom: 1px solid #E2E2E2;
    margin: -16px 0px 16px;

    .tab-button {
      flex: 1;
      background: none;
      border: none;
      padding: 12px;
      font-size: 14px;
      font-weight: 500;
      color: #666;
      cursor: pointer;
      position: relative;

      &:hover {
        color: #333;
      }

      &.active {
        color: #0066FF;

        &:after {
          content: '';
          position: absolute;
          bottom: -1px;
          left: 0;
          right: 0;
          height: 2px;
          background: #0066FF;
        }
      }
    }
  }

  .gooten-dropdown {
    .dropdown-menu.show {
      transform: none !important;
      width: 100%;
      left: 0 !important;
      top: 100% !important;
      margin-top: 4px;
    }
  }

  .rolling-date-filter,
  .fixed-date-filter {
    margin-top: $spacing-04;
  }

  .date-picker-container {
    margin-top: $spacing-04;
  }

  .date-picker-wrapper {
    margin-bottom: $spacing-04;

    label {
      display: block;
      margin-bottom: $spacing-02;
      font-weight: 500;
    }

    .react-date-picker {
      width: 100%;
      
      &__wrapper {
        border: 1px solid #E2E2E2;
        border-radius: 12px;
        padding: $spacing-02;
      }

      &__inputGroup {
        padding: 0 $spacing-02;
      }
    }

    .error-message {
      color: #E6474F;
      font-size: 12px;
      margin-top: $spacing-02;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;
    margin-top: $spacing-04;
    padding-top: $spacing-04;
    border-top: 1px solid #E2E2E2;
  }
}
