@import "app/assets/css/variables.scss";

.routing-view {
  margin: $spacing-05;

  .routing-header {
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
    height: 66px;
  }

  .merchant-table {
    margin-top: 12px;

    .merchant-id-column {
      width: 100px;
    }
  }

  .table-body {
    tr:hover {
      background-color: rgb(244, 244, 244);
    }
  }

  .routing-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin: -7px 5px 0 0;
    }
  }

  .no-matching-results {
    text-align: center;
    width: 100%;
    padding: 40px 0;
    color: #595959;
  }
}