@import "app/assets/css/variables.scss";

.audit-info-modal {
  .audit-info-modal-header {
    display: flex;
    justify-content: space-between;
    gap: $spacing-04;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: $spacing-04;

    .header-icons {
      display: flex;
      gap: $spacing-01;
    }
  }

  .gooten-modal-content {
    width: 100%;
    max-width: 800px !important;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .audit-info-modal-footer {
    display: flex;
    justify-content: space-between;
    gap: $spacing-02;
    padding-top: $spacing-04;

    .message-pagination-container {
      display: flex;
      gap: $spacing-02;
      align-items: center;

      color: #595959;
    }
  }

  .audit-info-modal-data {
    margin-bottom: 0px;

    border: 1px solid #E8E8E8;
    border-radius: 8px;

    .overflow-guard,
    .monaco-editor {
      border-radius: 8px;
    }
  }
}