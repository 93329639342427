@import "app/assets/css/variables.scss";

.block-filter {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  .row-options {
    display: flex;
    justify-content: space-between;
    gap: $spacing-05;
    margin-top: 8px;
  }

  .column-1,
  .column-2,
  .column-3 {
    flex: 1;
  }

  .quantity-between-inputs {
    display: flex;
    justify-content: space-between;
    gap: 10px;

    >div {
      flex: 1;
    }

    label {
      white-space: nowrap;
    }
  }
}