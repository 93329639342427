@import "app/assets/css/variables.scss";

.shipping-accounts-table {
  padding-bottom: $spacing-06;

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 26px;
    font-size: 20px;
    font-weight: 700;
    margin: $spacing-03 0;
  }

  .no-accounts-message {
    text-align: center;
    padding: $spacing-06 !important;
  }
}
