.actions-cell {
  text-align: center;
  width: 80px;
}

.add-reason-button {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
}

.rank-cell {
  position: relative;

  .editable-rank {
    cursor: pointer;
    padding: 2px 4px;
    border-radius: 3px;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .rank-edit {
    .gooten-dropdown {
      display: flex;
    }
  }
}