@import "app/assets/css/variables.scss";

.add-edit-shipping-mapping-view {
  .shipping-mapping-card {
    padding-bottom: 0px;

    .shipping-mapping-information {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;

      @media screen and (max-width: 900px) {
        grid-template-columns: 1fr;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }
  }
}
