@import "app/assets/css/variables.scss";

.integrations-view {
  padding: $spacing-05;

  .integrations-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: $spacing-04;
    gap: $spacing-05;
  }
}