.payment-information {
    padding-bottom: 0px;
    .card-header {
        height: 39px;
    }

    .payment-method {
        padding-bottom: 12px;
    }

    .payment-method:not(:first-child) {
        border-top: 1px solid #E8E8E8;
        padding: 12px 0;
    }
    
}