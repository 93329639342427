@import "app/assets/css/variables.scss";

.retry-rules-component {
  .retry-rules-name {
    color: #2E2E2E;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px; 
  }

  .retry-rules-body {
    display: flex;
    justify-content: space-between;
    gap: $spacing-04;
    padding-top: $spacing-04;

    >div {
      flex: 1;
    }
  }

  &.edit-mode {
    padding-bottom: 0px;
  }
}