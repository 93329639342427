@import "app/assets/css/variables.scss";

.create-edit-reason-modal {
  .gooten-modal-content {
    width: 800px;
    max-width: 90vw;
  }

  .create-edit-reason-form {
    display: flex;
    flex-direction: column;
    gap: $spacing-04;

    .form-row {
      display: flex;
      gap: $spacing-04;
      width: 100%;

      .form-col {
        flex: 1;
      }
    }
  }
}
