.additional-strategy-options {
  .dropdown-toggle::after{
    margin-left: 12px;
  }

  .rows-per-page-dropdown {
    font-size: 16px;
  }

  .dropdown-menu {
    font-size: 16px;
  }
}