@import "app/assets/css/variables.scss";

.vendor-add-products {
  .upload-products-error {
    margin-bottom: $spacing-05;
  }
  
  .header-buttons {
    display: flex;
    flex-direction: row;
    gap: $spacing-03;
  }

  .card-body {
    display: flex;
    flex-direction: row;
    gap: $spacing-04;
  
    .left-panel {
      flex: 1;
      .search-bar {
        margin-bottom: $spacing-04;
      }
    }

    .right-panel {
      border: 1px solid #E2E2E2;
      border-radius: 16px;
      padding: $spacing-05;
      width: 100%;
      max-width: 350px;

      .selected-products-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
      }
    }

    @media screen and (max-width: 1280px) {
      .selected-products-header { 
        flex-direction: column !important;
      }

      .right-panel {
        width: 100%;
        max-width: 185px;
      }
    }

    @media screen and (max-width: 900px) {
      flex-direction: column-reverse;
      gap: $spacing-04;

      .selected-products-header { 
        flex-direction: row !important;
      }
      
      .right-panel {
        width: 100%;
        max-width: 100%;
      }
    }
  }

  .action-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      max-width: 220px;
      margin: 5px;
    }
  }

  .upload-products-error {
    cursor: pointer;

    &:hover {
      .additional-message {
        text-decoration: underline;
      }
    }

    .message {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}