@import "app/assets/css/variables.scss";

.vendor-group {
  display: flex;
  justify-content: space-between;
  gap: 20px;

  &:not(.edit-mode) {
    div {
      flex: 1;
    }
  }

  .dymamic-header {
    display: flex;
    flex-direction: column;

    &.full-width {
      flex: 1;
    }

    .header-text {
      font-size: 18px;
      font-weight: 400;
      line-height: 30px;
      color: #595959;
      padding-bottom: $spacing-04;
    }
  }

  .row-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-06;
    max-height: 42px;
  }

  .vendor-rank {
    width: 100px;
  }
}