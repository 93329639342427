@import "app/assets/css/variables.scss";

.additional-item-details {
  display: flex;
  flex-direction: column;
  gap: $spacing-04;

  .details-container {
    display: flex;
    gap: $spacing-06;
    align-items: flex-start;
    padding-top: $spacing-04;

    .item-image {
      width: 120px;
      height: 120px; 

      border-radius: $spacing-03;
      border: #E8E8E8 1px solid;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        border-radius: $spacing-03;
      }
    }

    .item-details {
      flex: 1;
      display: grid;
      gap: $spacing-02 $spacing-04;
      grid-template-columns: max-content 1fr;

      &.many {
        grid-template-columns: 1fr 1fr;
      }

      .item-property {
        display: contents;

        .property-name {
          grid-column: 1;
          color: #595959;
        }

        .property-value {
          grid-column: 2;
        }
      }
    }
  }

  .image-details {
    .image-details-table {
      td {
        vertical-align: top;
      }

      .our-url {
        word-break: break-all;
      }

      .action-icon {
        text-align: center;
      }
    }

    .image-error-message-bar {
      margin-top: $spacing-04;
    }
  }

  &.image-info-loading {
    .loading-animation {
      margin-top: $spacing-04;
      
      .spinner-border {
        width: 16px;
        height: 16px;
      }
    }
  }

  .image-load-error-message {
    display: flex;
    align-items: center;
    gap: $spacing-02;
    margin-top: $spacing-04;
    color: rgb(130, 18, 24);
    font-size: 14px;
  }
}
