
@import "app/assets/css/global.scss";
@import "app/assets/css/bootstrapOverrides.scss";

@font-face {
  font-family: 'OpenSans';
  src: url(assets/fonts/OpenSans-Regular.ttf) format('truetype');
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0px;
  background-color: #FCFCFC;
  font-family: 'Source Sans Pro', sans-serif !important;
  font-size: 16px;

  .root {
    height: 100%;

    .app {
      height: 100%;
    }
  }

  .ui.table {
    color: rgb(100, 107, 114);
  }

  .main-container {
    display: flex;
    overflow: hidden;
    padding-top: 78px;
    background-color: #121517;
  }
}