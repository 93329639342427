@import "app/assets/css/variables.scss";

.note-editor-container {
    border: 1px solid #ddd;
    border-radius: 10px;
    padding: 10px;
    margin-bottom: 10px;
    background-color: white;
    width: 100%;

    .ProseMirror {
        outline: none;
        padding: 5px;
        background-color: white;
        transition: border-color 0.3s ease, box-shadow 0.3s ease;
        border: none;
    }

    .ProseMirror:focus {
        border: none;
        box-shadow: none;
    }

    .editor-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .editor-buttons {
        display: flex;
        align-items: center;
        gap: 12px;

        .editor-button {
            background: none;
            border: none;
            border-radius: 5px;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            min-width: 20px;
            text-align: center;
            color: #000000;
            cursor: pointer;
            &:hover{
                background-color: #f0f0f0;
            }
            &.active {
                color: #007bff;
                font-weight: bold;
            }

            &.italic-button {
                font-style: italic;
            }


            &.bold-button {
                font-weight: 700;
            }

            &.strike-button {
                text-decoration: line-through;
            }

            &.underline-button {
                text-decoration: underline;
            }
        }
    }

    .private-checkbox {
        display: flex;
        align-items: center;
        margin-left: 10px;

        label {
            margin: 0;
        }

        input {
            margin-right: 7px;
            transform: scale(1.25);
            accent-color: black;
            &:hover{
                background-color: #f0f0f0;
            }
        }
    }

    .char-count {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #878787;
    }

    .submit-section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }

    .submit-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 42px;
        height: 34px;
        background: #171717;
        border-radius: 8px;
        cursor: pointer;
        border-style: none;

        svg {
            width: 16px;
            height: 16px;
            color: #FFFFFF;
        }
    }

    .tiptap p.is-editor-empty:first-child::before {
        color: #adb5bd;
        content: attr(data-placeholder);
        float: left;
        height: 0;
        pointer-events: none;
    }

    .edit-action-buttons {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-direction: column;
    }

    .edit-submit-button {
        margin-left: 12px;
    }
}