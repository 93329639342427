@import "app/assets/css/variables.scss";

.status-cards-container {
  .status-cards-header {
    color: #2E2E2E;
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    margin-bottom: $spacing-04;
  }

  .status-cards {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
    gap: $spacing-03;
    width: 100%;
  }

  @media (max-width: 1200px) {
    .status-cards {
        grid-template-columns: repeat(auto-fit, minmax(170px, 1fr));
    }
}

  .gooten-card {
    padding-bottom: 0px;
  }

  .error-message {
    svg {
      margin: -7px 5px 0 0 ;
    }
  }
}