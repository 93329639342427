.data-preview {
  position: relative;

  .preview-table {
    position: relative;
  
    .loading-animation {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
    }

    .loading-overlay {
      position: fixed;
      top: 0px;
      left: 229px;
      right: 0;
      bottom: 0;
      z-index: 2;
    }

    .no-results-message {
      text-align: center;
      padding: 20px;
      color: #666;
      font-style: italic;
      height: 150px;
    }
  }
}