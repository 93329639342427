@import "app/assets/css/variables.scss";

.webhooks-list {
  .search-bar {
    margin-bottom: $spacing-04;
  }

  .table-header {
    .user-id-column {
      width: 225px;
    }
  }

  .table-body {
    .webhook-row {
      .gooten-toggle-switch {
        margin: 0 auto;
      }

      .webhook-status {
        .status-chip,
        button {
          margin: 0 auto;
        }

        button {
          height: 32px;
        }
      }
      .webhook-actions-menu {
        text-align: center;
      }
    }
  }

  .gooten-error-message {
    margin-top: $spacing-04;
  }
}