@import "app/assets/css/variables.scss";

.status-card {
  flex-grow: 1;
  flex-basis: 0; 
  background: #FCFCFC;
  border: 1px solid #E2E2E2;
  border-radius: 16px;
  padding: $spacing-05 $spacing-05 0 $spacing-05;
  cursor: pointer;

  .status-card-top {
    display: flex;
    
    .status-card-icon {
      display: inline-flex; 
      justify-content: center; 
      align-items: center; 
      width: 24px; 
      height: 24px; 
      padding: 4px;
      border-radius: $spacing-02;

      svg {
        width: 16px;
        height: 16px;
      }

      &.color-green {
        background-color: rgba(29, 177, 72, 0.10);

        svg {
          fill: #15602B;
        }
      }

      &.color-red {
        background-color: rgba(218, 30, 40, 0.10);

        svg {
          fill: #821218;
        }
      }

      &.color-yellow {
        background-color: #FCF4D4;

        svg {
          fill: #655006;
        }
      }
    }

    .status-card-label {
      margin-left: $spacing-04;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #878787;
      overflow: hidden; 
      text-overflow: ellipsis; 
    }
  }

  .status-card-bottom {
    display: flex;
    gap: $spacing-04;
    align-items: center;

    .status-card-value {
      font-size: 32px;
      font-weight: 600;
      line-height: 56px; 
      letter-spacing: 0.5px;
    }

    .status-card-percent {
      display: inline-flex; 
      gap: $spacing-03;
      justify-content: center; 
      align-items: center; 
      padding: 0px 10px;
      border-radius: 100px;
      height: 24px;
      font-size: 14px;

      svg {
        width: 16px;
        height: 16px;
      }

      &.color-green {
        background-color: rgba(29, 177, 72, 0.10);
        color: #15602B;
      }

      &.color-red {
        background-color: rgba(218, 30, 40, 0.10);
        color: #821218;
      }

      &.color-yellow {
        background-color: #FCF4D4;
        color: #655006;
      }
    }
  }
}