@import "app/assets/css/variables.scss";

.webhook-action-history {
  .history-description {
    max-width: 300px;
    white-space: wrap;
    word-wrap: break-word;
  }

  .history-date {
    white-space: nowrap;
  }

  .event-type-dropdown {
    max-width: 250px;

    position: absolute;
    top: 172px;
    right: 32px;
  }

  .no-results {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
  }
}