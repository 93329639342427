@import "app/assets/css/variables.scss";

.gooten-dropdown {
  position: relative;
  width: 100%;

  &.disabled {
    label {
      color: #B5B5B5;
    }
  }

  label {
    color: #2E2E2E;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 4px;
  }

  .dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-02;

    .dropdown-button-label {
      flex-grow: 1;
      text-align: left;
      font-size: 16px;
      line-height: 24px;
    }

    &.gooten-placeholder {
      .dropdown-button-label {
        color: #878787;
      }
    }

    .dropdown-caret-icon {
      width: 20px;
      text-align: right;
    }
  }

  button {
    color: #2E2E2E !important;
    padding: $spacing-03 $spacing-05;
    background-color: #FCFCFC !important;
    border: 1px solid #E2E2E2 !important;
    font-size: 16px;
    width: 100%;
    text-align: left;
    overflow: hidden;
    height: 44px;

    &::placeholder { 
      font-size: 16px; 
      color: #878787;  
      opacity: 1; 
    }

    .clear-icon {
      margin-right: 5px;

      cursor: pointer;
      &:hover {
        color: #E6474F;
      }
    }

    &::after {
      display: none;
    }

    &.readonly {
      cursor: default;
      &:after {
        display: none;
      }
    }

    // this is the focus state for the dropdown when it's not readonly or disabled
    &:not([readonly]):not(:disabled):focus-visible {
      background-color: #F2F2F2 !important;
      border: 1px solid transparent !important;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    // this is the hover state for the dropdown when it's not readonly or disabled
    &:not(.readonly):not(:disabled):hover {
      box-shadow: 0 0 0 1px #E2E2E2;
    }

    &:disabled {
      background-color: #E2E2E2 !important;
      color: #B5B5B5 !important;
      opacity: 1 !important;
    }

    &.has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
    }

    svg {
      color: #7f7f7f;
    }
  }

  .dropdown-menu.show {
    background-color: #FCFCFC;
    padding: $spacing-03;
    border-radius: 6px;
    border-color: #E2E2E2;

    // this styles the scrollbar track (the part the thumb moves along) 
    &::-webkit-scrollbar-track {
      background-color: #F2F2F2;
      border-radius: 8px;
    }

    // this styles the scrollbar thumb (the part you drag) 
    &::-webkit-scrollbar-thumb {
      background-color: #b3b3b3; 
      border-radius: 4px;
    }

    // this styles the scrollbar itself (including the track and thumb) 
    &::-webkit-scrollbar {
      width: 8px; 
      height: 8px; 
    }

    .search-container {
      display: flex;
      align-items: center;
      padding: $spacing-02;
      margin-bottom: $spacing-02;
      border-bottom: 1px solid #E2E2E2 !important;

      .search-icon {
        margin-right: $spacing-02;
        color: #7f7f7f;
      }

      .dropdown-search {
        width: 100%;
        padding: $spacing-02;
        border: 0;
        
        font-size: 16px;

        &:focus-visible {
          outline: none;
        }
      }
    }

    .no-matches {
      color: #B5B5B5;
      cursor: default;
      text-align: center;
      padding: $spacing-03;
      &:hover {
        background-color: #FCFCFC;
      }
    }
  }

  .dropdown-item {
    font-size: 16px;
    line-height: 24px;
    padding: $spacing-03;
    border-radius: $spacing-03;
    color: #2E2E2E !important;

    &.destructive {
      color: #821218;
      &:hover {
        background-color: rgba(218, 30, 40, 0.10);
      }
    }

    &:hover {
      background-color: #EDEDED;
    }
    &:active {
      background-color: #E0E0E0;
    }
    &:disabled {
      color: #B5B5B5;
    }
  }

  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #AF1820;
  }

  .question-icon {
    position: absolute;
    top: 21px;
    right: -4px;
    color: #7c7c7c;
    cursor: pointer;
    font-size: 16px;

    &.no-label {
      top: -10px;
    }
  }
}
