@import "app/assets/css/variables.scss";

.actions-and-trigger {
  border: 1px solid #E8E8E8;
  border-radius: 12px;
  padding: $spacing-03 $spacing-04;
  margin-top: $spacing-04;

  .actions-and-trigger-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    font-size: 20px;
    font-weight: 700;
    line-height: 32px;

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-04;
    }

    &.menu-open {
      border-bottom: 1px solid #E8E8E8;
      padding-bottom: $spacing-03;
      margin-bottom: $spacing-04;
    }
  }

  .actions-and-trigger-body {
    .edit-mode-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .action-buttons {
        display: flex;
        align-items: flex-start;
        gap: $spacing-04;
        margin-top: $spacing-04;
      }
    }

    .selected-event {
      max-width: 400px;
    }

    
    .code-snippet-header,
    .example-header {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
      padding: $spacing-04 0;
    }

    .example-header {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-04;
      user-select: none;
      cursor: pointer;

      .example-payload-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-02;
        justify-content: space-between;
      }
    }

    .code-snippet,
    .example-payload,
    .example-code-snippet {
      margin-bottom: 0px;

      border: 1px solid #E8E8E8;
      border-radius: 8px;

      .overflow-guard,
      .monaco-editor {
        border-radius: 8px;
      }
    }

    .copy-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-02;
    }

    .example-code-editors {
      display: flex;
      flex-direction: row;
      gap: $spacing-04;
    }

    .example-code-editors {
      position: relative;
    }
    
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #E8E8E8;
      font-size: 16px;
      z-index: 10;
      pointer-events: none;
      width: 50%;
    }
  }

  .gooten-error-message {
    margin: $spacing-04 0;
  }
}
