@import "app/assets/css/variables.scss";

.group-component {
  background-color: #FAFAFA;
  margin-bottom: $spacing-06;

  &.edit-mode {
    padding-bottom: 0px;
  }

  .group-component-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &.edit-mode {
      padding-bottom: 0px;
    }

    .group-name {
      color: #2E2E2E;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px; 
      margin-bottom: $spacing-04;
    }

    .group-name-input {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      width: 100%;
      max-width: 400px;
    }

    .sorting-mechanisms {
      display: flex;
      justify-content: flex-start;
      gap: $spacing-07;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
      color: #2E2E2E;
    }

    .section-options {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
    }
  }

  .group-body {
    .top-inputs-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-07;
      padding-top: $spacing-05;

      >div {
        flex: 1;
      }
    }
  }
}