@import "app/assets/css/variables.scss";

.bool-filter {
  .gooten-modal-content {
    width: 100%;
    max-width: 380px;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .gooten-dropdown {
    .dropdown-menu.show {
      transform: none !important;
      width: 100%;
      left: 0 !important;
      top: 100% !important;
      margin-top: 4px;
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;
    margin-top: $spacing-04;
  }
}
