@import "app/assets/css/variables.scss";

.plugin-marketplace-settings {
  .plugin-description-container {
    display: flex;
    flex-direction: column;

    .max-characters {
      font-size: 14px;
      line-height: 24px;
      color: #878787;
      margin-top: 2px;
    }
  }

  .upload-container {
    margin-top: 20px;

    .upload-label {
      width: 100%;
      color: #2E2E2E;
      font-size: 16px;
      line-height: 26px;
      margin-bottom: 8px;

      .upload-label-text {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .error-message {
          font-size: 12px;
          letter-spacing: 0.5px;
          color: #E6474F;
        }
      }
    }

    .upload-box {
      border: 2px dashed #d3d3d3;
      padding: 20px;
      text-align: center;
      border-radius: 8px;
      background-color: #f9f9f9;
      cursor: pointer;
      transition: background-color 0.3s ease;

      &.error {
        border-color: #E6474F;
      }

      &.dragging {
        background-color: #e3f2fd;
        border-color: #1264DF;   
      }

      .upload-icon {
        font-size: 24px;
        color: #1264DF;
        margin-bottom: 10px;
      }

      .upload-text {
        color: #2E2E2E;
        font-size: 16px;
        line-height: 26px;
      }

      .upload-button {
        margin: 10px auto;
      }

      .upload-instructions {
        color: #878787;
        text-align: center;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .uploaded-file-container {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 16px;
      border: 1px solid #d3d3d3;
      border-radius: 8px;

      .uploaded-logo {
        height: 80px;
        object-fit: cover;
        border-radius: 4px;
      }

      .file-details {
        flex: 1;

        .file-name {
          color: #2E2E2E;
          font-size: 14px;
          line-height: 24px;
        }

        .image-dimensions {
          color: #1264DF;
          font-size: 14px;
          margin: $spacing-03 0 0 0;
        }
      }

      .remove-button {
        margin: 30px 0 0 0;
      }
    }

    .image-url-input {
      margin-top: 10px;
    }
  }
}


