@import "app/assets/css/variables.scss";

.notes-card {

  .note-header {
    display: flex;
    gap: 4px;

    .note-count {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  .card-header {
    height: 39px;
  }

  .notes-card-data {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    word-break: break-word;

    .note-row {
      display: flex;
      align-items: left;
      gap: $spacing-04;

      .note-row-left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .note-icon {
          flex: 0 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 18px;
            height: 18px;
            fill: #878787;
            padding: 4px;
            background-color: #E8E8E8;
            border-radius: 999px;
          }
        }

        .vertical-line {
          width: 1px;
          height: 100%;
          background-color: #E8E8E8;
          margin-top: 5px;
        }
      }

      .note-row-right {
        overflow-wrap: break-word;

        .note-row-date {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #595959;
        }

        .note-row-author {
          font-size: 16px;
          font-weight: bold;
          line-height: 2;
        }

        .note-row-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #2E2E2E;
          overflow: 'hidden';
        }
      }
    }

    .view-all-notes {
      display: block;
      text-align: center;
      margin-top: 16px;
      padding: 8px;
    }
  }

}