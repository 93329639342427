@import "app/assets/css/variables.scss";

.gooten-checkbox-wrapper {
  display: flex;
  align-items: center;

  .gooten-checkbox {
    position: relative;
    cursor: pointer;
    background: #FCFCFC;
    user-select: none;
  
    .checkbox-icon {
      position: absolute;
      fill: #ffffff; 
    }
  
    &.size-small {
      border-radius: $spacing-02;
      border: 2px solid #E8E8E8;
      width: 18px;
      height: 18px;
  
      .checkbox-icon {
        top: -1px;
        left: -1px;
        width: 16px;
        height: 16px;
      }
    }
  
    &.size-medium {
      border-radius: $spacing-02;
      border: 3px solid #E8E8E8;
      width: 24px;
      height: 24px;
  
      .checkbox-icon {
        top: -1px;
        left: -1px;
        width: 20px;
        height: 20px;
      }
    }
  
    &.size-large {
      border-radius: $spacing-03;
      border: 3px solid #E8E8E8;
      width: 32px;
      height: 32px;
  
      .checkbox-icon {
        top: -3px;
        left: -3px;
        width: 32px;
        height: 32px;
      }
    }
  
    &:hover {
      background-color: #F2F2F2;
    }
  
    &:active {
      box-shadow: none;
    }
  
    &:focus-visible {
      outline: none;
      box-shadow: 0 0 0 4px #CBDEFB;
      background-color: #FCFCFC;
    }
  
    &.disabled {
      cursor: default;
      background-color: #F2F2F2;
      border-color: #E2E2E2;
      box-shadow: none;
    }
  
    &.checked {
      background-color: #171717;
      border-color: #171717;
  
      &:hover {
        background-color: #2E2E2E;
        border-color: #2E2E2E;
      }
  
      &.disabled {
        cursor: default;
        background-color: rgba(46, 46, 46, 0.4);
        
        box-shadow: none;
  
        &.size-small {
          border: 2px solid transparent;
        }
      
        &.size-medium {
          border: 3px solid transparent;
        }
      
        &.size-large {
          border: 3px solid transparent;
        }
      }
    }
  }
  
  .gooten-checkbox-label {
    margin-left: $spacing-03;
    color: #333;
  }
}


