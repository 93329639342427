@import "app/assets/css/variables.scss";

.gooten-tabbed-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  
  .tabbed-menu__tabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-04;
    margin-bottom: 20px;
  }
  
  .tabbed-menu__tab {
    position: relative;
    padding: $spacing-03 $spacing-05;
    font-size: 14px;
    color: #878787;
    cursor: pointer;
  }
  
  .tabbed-menu__tab:last-of-type {
    margin-right: 0;
  }
  
  .tabbed-menu__tab:hover {
    background-color: #E0E3E6;
    border-radius: $spacing-03;
  }
  
  .tabbed-menu__tab.active {
    color: #2E2E2E;
  }

  .tabbed-menu__tab.active:hover {
    border-radius: $spacing-03 $spacing-03 0 0;
  }

  
  .tabbed-menu__tab-underline {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #06BCE5;
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
  }
  
  .tabbed-menu__tab-underline.animate {
    transform: scaleX(1);
  }
}