@import "app/assets/css/variables.scss";

.products-view {
  .card-header {
    height: 40px;
  }
  
  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: $spacing-04 0;

    .search-bar {
      width: 100%;
    }
  }

  .table-body {
    tr:hover {
      background-color: rgb(244, 244, 244);
    }

    .product-status-chip {
      .status-chip {
        margin: 0 auto;
      }
    } 

    .action-icons {
      text-align: center;
    }

    .no-results {
      text-align: center;
      padding: 50px 0 !important;
    }
  }

  .product-options {
    display: flex;
    flex-direction: row;
    gap: $spacing-02;
  }

  .no-results {
    text-align: center;
    width: 100%;
    padding: 40px 0;
  }
  
  .error-message {
    text-align: center;
    padding: 50px !important;

    svg {
      margin: -6px 3px 0 0;
    }
  }

  .product-view-search-bar {
    .cta-button {
      svg {
        margin-right: 5px;
        margin-top: -5px;
        font-size: 15px;
      }
    }
  }

  .upload-products-error {
    margin-bottom: $spacing-05;
    cursor: pointer;

    &:hover {
      .additional-message {
        text-decoration: underline;
      }
    }

    .message {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .gooten-modal .gooten-modal-content {
    width: 400px;
  }
}