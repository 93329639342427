@import "app/assets/css/variables.scss";

.permission-denied {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: $spacing-05; 
  height: 60vh;

  svg {
    margin-right: 40px;
  }

  .title {
    font-size: 32px;
    font-weight: 600;
    line-height: 56px;
    letter-spacing: 0.5px;
  }

  .desc-line-1 {
    font-size: 18px;
    font-weight: 400;
    line-height: 30px; 
  }

  .desc-line-2 {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
  }

  .action-buttons {
    display: flex;
    flex-direction: row;
    margin-top: $spacing-05;
  }
}
