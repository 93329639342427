@import "app/assets/css/variables.scss";

.history-card {
  .card-header {
    height: 39px;
  }
  
  .history-card-data {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    margin-bottom: $spacing-04;
    word-break: break-word;

    .history-row {
      display: flex;
      align-items: left;
      gap: $spacing-04;

      .history-row-left {
        display: flex;
        flex-direction: column;
        align-items: center;

        .history-icon {
          flex: 0 0 auto;
          display: flex;
          justify-content: center;
          align-items: center;
          
          svg {
            width: 18px;
            height: 18px;
            fill: #878787;
            padding: 4px;
            background-color: #E8E8E8;
            border-radius: 999px;
          }
        }

        .vertical-line {
          width: 1px;
          height: 100%;
          background-color: #E8E8E8;
          margin-top: 5px;
        }
      }

      .history-row-right {
        overflow-wrap: break-word;
        
        .history-row-date {
          font-size: 12px;
          font-weight: 400;
          line-height: 18px;
          color: #595959;
        }
        .history-row-description {
          font-size: 16px;
          font-weight: 400;
          line-height: 24px;
          color: #2E2E2E;
        }
      }
    }
  }
}