@import "app/assets/css/variables.scss";

.gooten-table-header {
  border-bottom: 2px solid #B5B5B5;

  th {
    color: #2E2E2E;
    border: none;
    padding: $spacing-04;
    cursor: default;

    .tooltip-icon {
      margin-left: -4px;
      display: inline-flex;
      align-items: center;
      
      svg {
        font-size: 13px;
      }
    }

    &.orderable {
      cursor: pointer;

      .column-header {
        cursor: pointer;
      }
    }

    &.draggable {
      position: relative;
      transition: background-color 0.2s ease;
      
      .column-header {
        cursor: grab;
      }
    }

    &.dragging {
      opacity: 0.6;
      background-color: rgba(25, 105, 224, 0.05);
      border-left: 1px dashed rgba(25, 105, 224, 0.5);
      border-right: 1px dashed rgba(25, 105, 224, 0.5);
    }

    &.drag-over {
      background-color: rgba(25, 105, 224, 0.1);
      
      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 3px;
        background-color: #1969E0;
      }
    }

    &:first-child {
      padding-left: $spacing-07 !important;
    }

    &:last-child {
      padding-right: $spacing-07 !important;
    }

    .column-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;

      &.left {
        justify-content: left;
      }

      &.center {
        justify-content: center;
      }

      &.right {
        justify-content: flex-end;
      }

      .drag-handle {
        display: flex;
        align-items: center;
        opacity: 0.3;
        cursor: grab;
        margin-right: -5px;
        color: #666;
        
        svg {
          width: 16px;
          height: 16px;
        }
        
        &:hover {
          opacity: 0.8;
        }
        
        .draggable:hover & {
          opacity: 0.6;
        }
      }

      .header-icons {
        display: flex;
        align-items: center;
        gap: 4px;
        margin-left: 4px;
      }

      .filter-icon {
        display: flex;
        align-items: center;
        opacity: 0.6;
        transition: opacity 0.2s ease;
        z-index: 1;
        cursor: pointer;

        svg {
          width: 18px;
          height: 18px;
          transform: translateY(1px);

          &.active {
            color: #1969E0;
          }
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .order-status-header {
    text-align: center;
  }
}

body.column-dragging {
  cursor: grabbing !important;
  
  .gooten-table-header th.draggable:not(.dragging) {
    transition: transform 0.2s ease;
    
    &:hover {
      transform: translateX(2px);
    }
  }
}