@import "app/assets/css/variables.scss";

.associations-card {
  .association-details {
    .association-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $spacing-05;

      &>div {
        flex:1;
      }

      .merchant-selector {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-04;

        .action-buttons {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          gap: $spacing-04;
        }
      }
    }

    &.edit-mode {
      .association-top {
        padding-bottom: 0;
      }
    }
  }

  .association-bottom {
    .children-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      color: #2E2E2E;
      font-size: 18px;
      font-weight: 600;
      line-height: 30px;
      padding-bottom: $spacing-05;
    }

    .children-merchants {
      .table-body {
        // for the .merchant-row, we need to set the width of the first two columns
        // to 29% and 79% respectively. The third column should be 2% and text-align: right
        .merchant-row {
          cursor: pointer;
          td:first-child {
            width: 29%;
          }
          td:nth-child(2) {
            width: 79%;
          }
          td:nth-child(3) {
            width: 2%;
            text-align: right;
          }

          &.new-merchant {
            td {
              overflow: visible;
              padding-bottom: 0px !important;
            }
          }
        }
      }
    }
  }
}



//   max-width: 1000px;

//   .association-details {
//     margin: -16px -16px 16px -16px;
//     padding: 24px 16px 4px 16px;

//     .association-top {
//       display: grid;
//       grid-template-columns: 2fr 2fr 1fr;
//       row-gap: 16px;
//       column-gap: 20px;
//     }

//     .association-bottom,
//     .association-bottom-edit-mode {
//       .child-merchants {
//         table {
//           margin-bottom: 0px;
//         }

//         .table-body {
//           .merchant-row {
//             cursor: default;
//           }
//           .merchant-row td:first-child {
//             width: 29%;
//           }
//           .merchant-row td:nth-child(2) {
//             width: 79%;
//           }
//           .merchant-row td:nth-child(3) {
//             width: 2%;
//             text-align: right;
//           }
//         }

//         button {
//           font-size: 12px;
//           background-color: #dcdcdc;
//           margin: 0px 2px !important;
//           height: 19px;
  
//           svg {
//             margin-top: -8px;
//           }
//         }
//       }
//     }

//     .association-bottom {
//       .child-merchants {
//         .table-body {
//           tr:hover {
//             background-color: rgb(244, 244, 244);
//           }
      
//           .merchant-row {
//             cursor: pointer;
//           }
//         }
//       }
//     }

//     .with-floating-label {
//       margin-bottom: 0px;
//     }

//     @media screen and (max-width: 768px) {
//       grid-template-columns: 1fr; 
//     }
//   }

//   .association-card-button {
//     font-size: 0.7em;
//     padding: 0px 5px !important;
//     background-color: #e8e8e8;
//     height: 19px;
//     margin: auto 7px !important;
//   }

//   .merchant-children-header-container {
//     position: relative;
//     height: 20px;
//     border-top: 1px solid #ccc;
//     margin: 24px -16px 0px -16px;

//     .children-header {
//       position: absolute;
//       color: #808080;
//       top: -10px;
//       left: 26px;
//       padding: 0 3px;
//       background-color: #fff;
//       font-size: 12px;
//       border-radius: 5px;
//     }
//   }

//   .action-buttons {
//     display: flex;
//     justify-content: flex-end;
    
//     .btn-primary {
//       margin-left: 8px;
//     }
//   }
// }