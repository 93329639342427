@import "app/assets/css/variables.scss";

.block-component {
  margin-top: $spacing-05;

  .block-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(.edit-mode) {
      border-bottom: none;
    }
    
    .block-name-input {
      width: 400px;
    }

    .section-options {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      gap: 6px;
    }
  }

  .block-name {
    color: #2E2E2E;
    font-size: 20px;
    font-weight: 700;
    line-height: 32px; 
    margin-bottom: $spacing-05;
  }
}