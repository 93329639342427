.sort-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;

  .arrow-up-active,
  .arrow-down-active {
    color: #595959;
  }
  
  .arrow-up-inactive,
  .arrow-down-inactive {
    color:#a5a5a5;
  }

  .arrow-down-active,
  .arrow-down-inactive {
    margin-top: -3px;
    height: 12px;
  }

  .arrow-up-active,
  .arrow-up-inactive {
    height: 12px;
    margin-top: 3px;
  }
}