@import "app/assets/css/variables.scss";

.view-as-menu {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin: 0 8px;
  padding-bottom: $spacing-05;
  border-bottom: 1px solid #2E2E2E;
  margin-bottom: $spacing-05;
  
  .view-as-menu-content {
    display: flex;
    flex-direction: row;
    gap: 10px;
    cursor: pointer;
  }

  .view-as-menu-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #8BE8FC;
    padding: 4px;
    border-radius: 50%;
    width: 25px;
    height: 25px;

    svg {
      box-shadow: 0 0 100px 0px #8BE8FC;
      background-color: #8BE8FC;
      width: 100%;
      height: 100%;
    }
  }

  .view-as-menu-text {
    display: flex;
    flex-direction: column;
    gap: 0;
    flex: 1;
    min-width: 0;

    .view-as-menu-title {
      color: #878787;
      font-size: 12px;
      line-height: 13px;
      margin-top: -2px;
    }

    .view-as-menu-user {
      color: #FFFFFF;
      font-size: 16px;
      line-height: 17px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
    }
  }

  .arrow-icon {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: #878787;
    }
  }
}

.view-as-modal {
  width: auto;
  height: auto;
  top: 106px;
  left: 8px;

  .view-as-search {
    width: 100%;

    .view-as-table {
      display: flex;
      flex-direction: column;
      height: 200px;
      overflow-y: scroll;
      line-height: 24px;
      padding-top: 10px;

      // this styles the scrollbar track (the part the thumb moves along) 
      &::-webkit-scrollbar-track {
        background-color: #F2F2F2;
        border-radius: 8px;
      }

      // this styles the scrollbar thumb (the part you drag) 
      &::-webkit-scrollbar-thumb {
        background-color: #b3b3b3; 
        border-radius: 4px;
      }

      // this styles the scrollbar itself (including the track and thumb) 
      &::-webkit-scrollbar {
        width: 8px; 
        height: 8px; 
      }

      .view-as-table-item {
        cursor: pointer;
        display: block;
        border-bottom: none;
        border-radius: 10px;
        margin-right: $spacing-03;
        color: #595959;

        &:hover {
          background-color: #EDEDED;
        }

        td {
          padding-left: $spacing-03 !important;
        }

        &.selected {
          background-color: #E8E8E8;
          padding: 0px;
        }
      }

      .no-results {
        td {
          color: #595959;
          padding-left: $spacing-03 !important;
        }
      }
    }

    .hover-text {
      display: flex;
      align-items: center;
      position: absolute;
      color: #3982EF;
      padding: 3px 7px;
      font-size: 15px;
      z-index: 100;
      bottom: 11px;
      left: 8px;
      gap: $spacing-02;
      white-space: nowrap;

      span {
        margin-left: 5px;
      }
    }
  }

  .gooten-modal-content {
    padding: 8px;
    border-radius: 8px;
  }

  .view-as-modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: $spacing-05;
    padding-bottom: $spacing-03;
    margin-bottom: $spacing-03;
    border-bottom: 1px solid #E8E8E8;
    width: 100%;
    padding-left: 8px;

    .header-title {
      font-size: 16px;
      font-weight: 700;
    }
  }

  .gooten-modal-body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: $spacing-03;
    width: 300px;

    .loading-animation {
      height: 72px;
      padding-top: 10%;
      padding-left: 41%;
    }
  }
}