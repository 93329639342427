@import "app/assets/css/variables.scss";

.gooten-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1051;
  transition: all 0.3s;

   .gooten-modal-content {
    background: #FCFCFC;
    padding: $spacing-05;
    margin: $spacing-05;
    border-radius: 16px;
    box-shadow: 0px 2px 3px 0px rgba(23, 23, 23, 0.10);
    z-index: 1052;
    position: relative;
    max-width: 500px;

    .gooten-modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding-bottom: $spacing-04;
      border-bottom: 1px solid #E8E8E8;

      .gooten-modal-title {
        font-size: 20px;
        font-weight: 700;
      }
    
      .gooten-modal-subtitle {
        font-size: 16px;
        font-weight: 400;
      }

      .close-button {
        background: none;
        border: none;
        font-size: 24px;
        cursor: pointer;
      }
    }

    .gooten-modal-body {
      padding-bottom: $spacing-04;
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;

      &.has-title {
        padding-top: $spacing-04;
      }
    }
  
    .gooten-modal-footer {
      display: flex;
      justify-content: flex-end;
      gap: $spacing-04;
    }
  }

  .gooten-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
  }
}
