@import "app/assets/css/variables.scss";

.strategy-data-sets {
  padding: $spacing-05;

  .data-sets-header {
    justify-content: space-between;
    align-items: center;
  }

  .table-body {
    .data-set-row {

      .data-set-actions-menu {
        text-align: center;
      }
    }
  }
}