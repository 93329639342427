@import "app/assets/css/variables.scss";

.tree-node {
  margin-bottom: 8px;

  .node-content {
    display: flex;
    align-items: center;
    gap: 8px;
    
    .expand-button {
      padding: 2px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      
      &:hover {
        background-color: rgba(0, 0, 0, 0.05);
        border-radius: 4px;
      }
    }
    
    .node-label {
      font-weight: 500;
      cursor: default;
    }
  }

  .node-children {
    margin-top: 8px;
  }
}
