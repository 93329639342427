@import "app/assets/css/variables.scss";

.refund-view {
  .header-buttons {
    display: flex;
    flex-direction: row;
    gap: $spacing-04;
  }

  .costs-table {
    max-width: 400px;

    td:last-child {
      text-align: right;
    }
  }

  .summary-table {
    max-width: 400px;
    margin-left: auto;
    margin-top: $spacing-04;
  }

  .items-table {
    border-top: 1px solid #E8E8E8;
    margin-top: $spacing-04;

    td {
      vertical-align: top;
    }

    .details-row {
      &.selected {
        background-color: #F2F2F2;
      }

      .details-sku {
        word-break: break-all;
      }
    }

    .refundable-text {
      white-space: nowrap;
      
      svg {
        margin-left: $spacing-02;
        margin-top: -6px;
      }
    }
  }

  .table-body {
    .total-row {
      border-bottom: 1px solid #E8E8E8;

      &:last-child {
        border-bottom: none;
      }
    }

    .extra-border {
      border-bottom: 2px solid #B5B5B5;
    }
  }
}
