@import "app/assets/css/variables.scss";

.dashboard-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-06;
  margin: $spacing-05;
  
  .dashboard-view-header {
    display: flex;
    flex-direction: column;
    gap: $spacing-03;

    .title-and-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
    }

    .last-updated {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-size: 14px;
      color: #878787;
  
      svg {
        margin-right: $spacing-02;
      }
    }
  }

  .charts-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    gap: $spacing-06;
  }

  .gooten-dashboard-chart {
    flex: 1 1 calc(50% - $spacing-06/2);
    max-width: calc(50% - $spacing-06/2);
    height: auto;

    .apexcharts-canvas {
      width: 100% !important;
      height: auto !important;

      .apexcharts-title-text {
        color: #2E2E2E;
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 12px;  
      }
    }

    &.gooten-card {
      min-height: 380px;
      &.has-data {
        padding-bottom: 0px;
      }
    }

    .loading-header {
      color: #2E2E2E;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
      margin: -2px 0 12px 10px;  
    }
  }

  @media (max-width: 1050px) {
    .gooten-dashboard-chart {
      flex: 1 1 100%; 
      max-width: 100%;
    }
  }
}
