@import "app/assets/css/variables.scss";

.topbar {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 3;

  .view-as-container {
    position: fixed;
    top: 78px;
    left: 238px;
    z-index: -1;
    width: calc(100% - 229px);
    height: 28px;
    transition: left 0.3s ease-in-out, width 0.3s ease-in-out;
    animation: slideDown 0.5s ease-in-out forwards;

    .top-line {
      height: 4px;
      background-color: #8be8fc;
      border-top-left-radius: 40px 10px;
    }

    .view-as-message {
      position: absolute;
      left: 50%;
      margin-top: -4px;
      transform: translateX(-50%);
      max-width: 300px;
      height: 28px;
      border-radius: 0 0 12px 12px;
      padding: $spacing-04;
      color: #000000;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      font-size: 14px;
      background-color: #8be8fc;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      gap: 8px;

      .close-button {
        padding: 8px;
        font-size: 12px;
        height: 20px;
        border-radius: 4px;
      }

      &:hover {
        background-color: #a7efff;
      }

      .message-text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &.sidebar-closed {
      left: 0px;
      width: 100%;

      .top-line {
        border-top-left-radius: 0px;
      }
    }

    &.slide-out {
      animation: slideUp 0.5s ease-in-out forwards;
    }

    @keyframes slideDown {
      from {
        transform: translateY(-100%);
      }
      to {
        transform: translateY(0);
      }
    }

    @keyframes slideUp {
      from {
        transform: translateY(0);
      }
      to {
        transform: translateY(-100%);
      }
    }
  }

  .main-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 78px;
    padding: $spacing-03 $spacing-05 $spacing-03 $spacing-07;
    background-color: #121517;

    .logo-container {
      display: flex;
      align-items: center;
      width: 240px;

      .hamburber-icon-expand {
        display: inline-block;
        width: 18px;
        height: 11px;
        cursor: pointer;
      }

      .hamburber-icon-collapse {
        display: inline-block;
        width: 18px;
        height: 11px;
        cursor: pointer;
      }

      .order-mesh-logo {
        display: flex;
        margin-left: $spacing-04;
        color: #2e2e2e;
        font-size: 24px;
        font-weight: 600;
        cursor: pointer;
      }
    }

    .profile-button-menu {
      button {
        border-radius: $spacing-04;
      }

      .profile-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        color: #fff;

        .profile-name {
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          white-space: nowrap;
        }

        .profile-avatar {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          border-radius: 100px;
          background: linear-gradient(to bottom, #8feafb, #b4f8db);

          svg {
            fill: #000;
            width: 22px;
            height: 22px;
            position: absolute;
            margin: 0px;
          }
        }
      }
    }
  }
}
