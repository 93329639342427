@import "app/assets/css/variables.scss";

.inventory-view {
  padding: $spacing-05;

  .inventory-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
  }

  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: $spacing-06;

    .search-bar {
      width: 100%;
      max-width: 500px;
    }

    .vendor-selection {
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
    }
  }

  .table-body {
    .inventory-row {
      .inventory-status-chip {
        .status-chip {
          margin: 0 auto;
        }
      }

      .inventory-toggle-switch {
        .gooten-toggle-switch {
          margin: auto 12px;
        }
      }

      .error {
        border: none;
        font-size: 0px;
        line-height: 0px;
        height: 0px;
        letter-spacing: 0px;
      }

      .editable-input-cell {
        width: 150px;
      }

      .disabled-input-cell {
        width: 150px;
        input {
          border: none !important;
        }
      }

      .editable-dropdown-cell {
        width: 150px;
        margin: 0 auto;
      }
    }

    .error-message {
      text-align: center;
      padding: 50px !important;
      .centered-message {
        vertical-align: middle;
        padding: 15px;
      }
    }

    .disabled {
      color: #878787;
      background-color: transparent;
      cursor: default;
    }
  }
}
