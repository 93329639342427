@import "app/assets/css/variables.scss";

.gooten-table {
  margin-bottom: 0 !important;
  width: 100%;

  td {
    padding: $spacing-03 $spacing-04 !important;
  }

  td:first-child {
    padding-left: $spacing-07 !important;
  }

  td:last-child {
    padding-right : $spacing-07 !important;
  }


  &.size-small {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    th {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
    }
  }

  &.size-medium {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;

    th {
      font-size: 16px;
      font-weight: 400;
      line-height: 26px;
    }
  }

  &.vertical-align-top {
    td {
      vertical-align: top;
    }
  }
  &.vertical-align-middle {
    td {
      vertical-align: middle;
    }
  }
  &.vertical-align-bottom {
    td {
      vertical-align: bottom;
    }
  }
    
  .table-body {
    color: #595959;
    
    tr {
      border-bottom: 1px solid #E8E8E8;
    }

    a {
      color: #3982EF;
      text-decoration: none;
    }
  }

  &.highlight-rows {
    .table-body {
      tr:hover {
        background-color: rgba(244, 244, 244, 0.65);
      }
    }
  }
}