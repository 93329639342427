@import "app/assets/css/variables.scss";

.notifications-card {
  
  .card-header {
    line-height: 26px;
    font-size: 20px;
    font-weight: 700;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .dropdown-content {
    width: 373px;
  }

  .dropdown-notification {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: $spacing-05;

    @media screen and (max-width: 1200px) {
      grid-template-columns: 1fr;
    }
  }

  &.edit-mode {
    padding-bottom: 0px;
  }
}
