@import "app/assets/css/variables.scss";

.my-integrations {
  .my-integrations-card {
    &:not(.errors-found) {
      min-height: 201px;
    }
  }
  
  .my-integrations-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: $spacing-04;
  }

  .search-bar {
    margin-bottom: $spacing-05;
  }


  .my-integrations-content {
    display: flex;
    flex-direction: row;
    gap: $spacing-05;

    .content-categories {
      display: flex;
      flex-direction: column;
      gap: $spacing-03;
      width: 100%;
      max-width: 190px;

      .content-categories-header {
        font-size: 18px;
        font-weight: 700;
        line-height: 30px
      }

      .content-categories-list {
        display: flex;
        flex-direction: column;
        gap: $spacing-03;
        width: 100%;

        .content-category {
          display: flex;
          padding: $spacing-04 $spacing-05;
          font-size: 16px;
          font-weight: 700;
          line-height: 26px;
          border-radius: 12px;
          border: 1px solid #E8E8E8;
          cursor: pointer;

          &:hover {
            background-color: #EDEDED;
          }

          &.active {
            background-color: #EDEDED;
          }
        }

        @media (max-width: 780px) {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
          gap: $spacing-05;
          width: 100%;
        }
      }

      @media (max-width: 780px) {
       max-width: 100%;
      }
    }

    .content-list {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }

    @media (max-width: 780px) {
      flex-direction: column;
    }
  }

  .no-integrations-found {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    width: 100%;
    padding-bottom: 25px;
  }
}
