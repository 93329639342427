@import "app/assets/css/variables.scss";

.actions-and-triggers {
  display: flex;
  flex-direction: column;
  
  .card-header {
    height: 40px;
  };

  .actions-and-triggers-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-02;
  }

  .no-data {
    padding: $spacing-04 0 $spacing-02 0;
  }
}