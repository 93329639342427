.main-content {
  z-index: 2;
  background-color: #EDEDED;
  border-top-left-radius: 0px;
  transition: margin-left 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 78px);
  overflow-y: auto;

 &.sidebar-visible {
    border-top-left-radius: 16px;
    margin-left: 229px;
  }
}