@import "app/assets/css/variables.scss";

.integrations-manager {
  padding: $spacing-05;

  .merchants-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
    min-height: 66px;

    .merchants-header-title {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 0px;
      column-gap: $spacing-04;
    }
  }

  .search-bar-and-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-04;

    .search-bar {
      width: 100%;
    }
  }

  .table-body {
    .integration-actions {
      text-align: center;
    }
  }

  .no-matching-results {
    text-align: center;
    width: 100%;
    padding: 40px 0;
  }

  .error-message {
    text-align: center;
    padding: 50px !important;

    svg {
      margin: -6px 3px 0 0;
    }
  }
}