@import "app/assets/css/variables.scss";

.exports-view {
  padding: $spacing-05;

  .exports-view-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 32px;
    font-weight: 600;
    padding-bottom: $spacing-05;
    height: 66px;
  }

  .search-bar {
    margin-bottom: -$spacing-03;
  }

  .table-header {
    .user-id-column {
      width: 225px;
    }
  }

  .table-body {
    .export-row {
      .export-status {
        .status-chip {
          margin: 0 auto;
        }
      }
      .export-actions-menu {
        text-align: center;
      }
    }
  }

  .no-matching-results {
    text-align: center;
    width: 100%;
    padding: 40px 0;
  }
  
  .export-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
    }
  }
}