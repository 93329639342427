@import "app/assets/css/variables.scss";

.details-card {
  .details-row {
    &.all-items {
      border-bottom: 2px solid #B5B5B5;
    }

    .details-sku {
      word-break: break-all;
    }

    .details-chevron {
      svg {
        margin-top: -3px;
      }
    }
  }

  .table-body {
    .pagination-row {
      border-bottom: none;

      td:first-child {
        padding-left: 0 !important;
      }

      td:last-child {
        padding-right: 0 !important;
      }

      .gooten-pagination {
        margin-top: 0px;

        button {
          width: 120px !important;
        }

        .dropdown-menu {
          width: 120px !important;
        }
      }
    }

    .total-row {
      border-bottom: none;

      td:nth-child(2),
      td:last-child {
        border-bottom: 1px solid #E8E8E8;
      }
    }

    .extra-border {
      td:nth-child(2),
      td:last-child {
        border-bottom: 2px solid #B5B5B5;
      }
    }

    .total-row:last-child {
      td {
        border-bottom: none;
      }
    }
  }

  .pagination-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: $spacing-03;

    .current-page {
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      color: #878787;
    }
  }
}