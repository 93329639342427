@import "app/assets/css/variables.scss";

.gooten-button-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  border-radius: $spacing-02;
  border: none;

  &.size-small {
    button {
      padding: $spacing-02;
      border-radius: $spacing-02;
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &.size-medium {
    button {
      padding: $spacing-03;
      border-radius: $spacing-03;
    }
    svg {
      width: 18px;
      height: 18px;
    }
  }

  &.size-large {
    button {
      padding: $spacing-03;
      border-radius: $spacing-03;
    }
    svg {
      width: 22px;
      height: 22px;
    }
  }

  .gooten-button-icon-toggle {
    color: #2E2E2E;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;

    &.dark-mode {
      color: #B5B5B5;
    }

    &.destructive {
      color: #DA1E28;

      &:hover {
        color:#DA1E28;
        background-color: #f7e4e5;

        &.dark-mode {
          color:#DA1E28;
          background-color: rgba(218, 30, 40, 0.30);
        }
      }
    }
    
    &:hover {
      color: #2E2E2E;
      background-color: #E0E3E6;

      &.dark-mode {
        color: #B5B5B5;
        background-color: #2E2E2E;
      }
    }

    &:active {
      color: #2E2E2E;
      background-color: #C3CAD0;

      &.dark-mode {
        color: #B5B5B5;
        background-color: #2E2E2E;
      }
    }

    &:focus-visible {
      color: #2E2E2E;
      outline: none;
      box-shadow: 0 0 0 4px #CBDEFB;

      &.dark-mode {
        color: #B5B5B5;
        box-shadow: 0 0 0 4px #2E2E2E;
      }
    }

    &:disabled {
      color: #878787;
      background-color: transparent;
      cursor: default;

      &.dark-mode {
        color: #595959;
      }
    }

    &::after {
      display: none;
    }
  }

  .dropdown-menu {
    border-radius: $spacing-04;
    font-size: 16px;
    background-color: #FCFCFC;
    border: 1px solid #E2E2E2;
    padding: $spacing-03;
    box-shadow: 3px 4px 3px -3px rgba(23, 23, 23, 0.3);
    min-width: 0;

    .dropdown-item {
      font-size: 16px;
      line-height: 24px;
      padding: $spacing-03;
      border-radius: $spacing-03;
      color: #2E2E2E;

      &.destructive {
        color: #821218;
        &:hover {
          background-color: rgba(218, 30, 40, 0.10);
        }
      }

      &:hover {
        background-color: #EDEDED;
      }
      &:active {
        background-color: #E0E0E0;
      }
      &:disabled {
        color: #B5B5B5;
      }
    }
  }
}