@import "app/assets/css/variables.scss";

.client-manager-card-data {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    margin-bottom: $spacing-04;
    word-break: break-word;

    .client-manager-card {

        .action-buttons {
            display: flex;
            justify-content: flex-end;
            gap: $spacing-04;
            
            .btn-primary {
                margin-left: $spacing-03;
            }
        }

        .client-details {
            margin-top: $spacing-08;
            margin-bottom: $spacing-04;

            .data-point {
                &:not(:last-child) {
                    margin-bottom: $spacing-06;
                }
            }
        }
    }
}