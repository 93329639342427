@import "app/assets/css/variables.scss";

.user-details {
  margin: $spacing-05;

  >form {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;
  }

  .header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .user-name {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      gap: $spacing-05;
    }
  }

  .user-details-card {
    &.edit-mode {
      padding-bottom: 0px;
    }

    .card-header {
      line-height: 26px;
      font-size: 20px;
      font-weight: 700;
    }

    .user-details-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;

      .organizations-view {

        .view-as-table {
          display: flex;
          flex-direction: column;
          height: 200px;
          overflow-y: scroll;
          line-height: 30px;
          border: 1px #e2e2e2 solid;
          border-radius: 10px;
          border-top: none;
          padding: 10px;

          tr {
            border-bottom: none;
          }

          .line-item {
            display: flex;
            align-items: center;
            gap: $spacing-04;
            height: 38px;
            cursor: pointer;
          }
        }
      }

      &.view-mode {
        padding-bottom: $spacing-05;
        row-gap: $spacing-04;
      }

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }

      .grant-access {

        padding: var(--spacing-spacing-03, 8px);
        align-items: center;
        gap: var(--spacing-spacing-04, 12px);

        .grant-access-label {
          align-self: stretch;
          color: var(--Content-text-caption, #878787);
          /* Body/20 */
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 24px;
          padding-left: 26px;
        }
      }

      .dropdown-content {
        width: 373px;
      }
    }
  }

  .account-details {
    padding-bottom: 0px;

    .account-details-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;
      padding-bottom: $spacing-04;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }
  }

  .account-permissions-header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: $spacing-03;
    margin-top: $spacing-03;
  }

  .account-permissions-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
    padding-right: $spacing-09;
    padding-bottom: $spacing-03;
  }

  .account-permissions {
    padding-bottom: $spacing-05;
    padding-top: $spacing-05;

    .account-permissions-line {
      display: flex;
      justify-content: space-between;
      padding-bottom: $spacing-05;

      .account-permissions-title {
        display: flex;
        align-items: center;
        gap: $spacing-04;
        padding-left: $spacing-07;
      }

      .account-permissions-checkbox {
        display: flex;
        align-items: flex-end;
        gap: $spacing-04;
        padding-right: $spacing-09;
      }
    }
  }
}