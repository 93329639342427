@import "app/assets/css/variables.scss";

.onboard {
  padding: $spacing-03 $spacing-06;
  background-color: #EDEDED;
  min-height: 100vh;
  position: relative;

  .logo {
    width: 140px;
    position: absolute;
    top: $spacing-03;
    left: $spacing-06;
    cursor: pointer;
  }

  .onboard-content {
    display: flex;
    flex-direction: column;
    gap: $spacing-04;
    align-items: center;
    width: 100%;
    padding-top: 60px;

    @media screen and (min-height: 800px) {
      padding-top: 150px;
    }

    .header-card {
      width: 100%;
      max-width: 620px;

      .header-card-title {
        color: #2E2E2E;
        font-size: 24px;
        font-weight: 600;
      }

      .header-card-subtitle {
        color: #595959;
        font-size: 16px;
        font-weight: 400;
      }

      .gooten-error-message {
        margin-top: $spacing-03;
      }
    }

    .onboard-form-card {
      width: 100%;
      max-width: 620px;

      .input-container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $spacing-04;

        @media screen and (max-width: 480px) {
          grid-template-columns: 1fr;
        }
      }

      .submit-button-container {
        display: flex;
        justify-content: right;
      }
    }
  }
}