@import "app/assets/css/variables.scss";

.merchant-details-view {
  .merchant-details-view-cards {
    display: flex;
    justify-content: space-between;
    gap: $spacing-06;

    .left-col {
      flex: 2; 
    }
  
    .right-col {
      flex: 1; 
    }

    @media (max-width: 1200px) {
      flex-direction: column;
    }
  }
}