@import "app/assets/css/variables.scss";

.vas-view {
  .vas-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 20px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
}