@import "app/assets/css/variables.scss";

.role-details {
  margin: $spacing-05;

  .header-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-06;

    .role-name {
      display: flex;
      flex-direction: row;
      gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .role-header-group {
      display: flex;
      align-items: center;
      gap: $spacing-04;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-05;
    }
  }

  .role-details-card {
    &.edit-mode {
      padding-bottom: 0px;
    }

    .card-header {
      line-height: 26px;
      font-size: 20px;
      font-weight: 700;
    }

    .role-details-inputs {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: $spacing-05;

      &.view-mode {
        padding-bottom: $spacing-04;
        row-gap: $spacing-04;
      }

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr;
      }
    }

    .role-details {
      .role-details-header {
        line-height: 26px;
        font-size: 20px;
        font-weight: 700;
        padding: $spacing-05 0;
        border-bottom: 1px solid #E2E2E2;
        margin-bottom: $spacing-05;
      }

      .role-details-inputs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: $spacing-05;

        @media screen and (max-width: 1200px) {
          grid-template-columns: 1fr;
        }
      }
    }
  }

  .role-permissions-header {
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    margin-bottom: $spacing-03;
    margin-top: $spacing-03;
  }

  .role-permissions-container {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E2E2E2;
    padding-right: $spacing-09;
    padding-bottom: $spacing-03;
  }

  .role-permissions {
    padding-bottom: $spacing-05;
    padding-top: $spacing-05;

    .role-permissions-line {
      display: flex;
      justify-content: space-between;
      padding-bottom: $spacing-05;

      .role-permissions-title {
        display: flex;
        align-items: center;
        gap: $spacing-04;
        padding-left: $spacing-07;
      }

      .role-permissions-checkbox {
        display: flex;
        align-items: flex-end;
        gap: $spacing-04;
        padding-right: $spacing-09;
      }
    }
  }
}