@import "app/assets/css/variables.scss";

.orders-filter-menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: $spacing-04;

  .filter-fields {
    display: flex;
    gap: $spacing-04;
    width: 100%;

    .filter-field {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-03;
      padding: $spacing-03 $spacing-05;
      font-size: 18px;
      line-height: 30px;
      position: relative;
      color: #878787;

      &.selected {
        color: #2E2E2E;
      }

      .pin-fill {
        width: 16px;
        height: 16px;
      }

      .filter-name {
        cursor: pointer;
        white-space: nowrap;
      }

      &:after { 
        content: '';
        position: absolute;
        bottom: 0; 
        left: 0;
        right: 0;
        height: 2px;
        background-color: #06BCE5;
        transform: scaleX(0);
        transition: transform 0.3s ease-in-out; 
      }

      &.selected:after {
        transform: scaleX(1);
      }
    }
  }
}
