@import "app/assets/css/variables.scss";

.gooten-text-area {
  label {
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;
    margin-bottom: $spacing-02;

    &.disabled {
      color: #B5B5B5;
    }
  }

  textarea {
    color: #2E2E2E;
    font-size: 16px;
    border-radius: $spacing-03;
    background-color: #FCFCFC !important;
    border: 1px solid #E2E2E2 !important;
    padding: $spacing-03 $spacing-05;

    &:not([readonly]):not(:disabled):focus-visible {
      background-color: #FCFCFC;
      border: 1px solid transparent;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    &:not([readonly]):not(:disabled):hover {
      background-color: #FCFCFC;
      box-shadow: 0 0 0 1px #E2E2E2;
    }

    &:disabled {
      background-color: #E2E2E2;
      color: #B5B5B5;
    }

    &:not([readonly]):not(:disabled).has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
      border: 1px solid transparent !important;
    }

     /* This styles the scrollbar track (the part the thumb moves along) */
     &::-webkit-scrollbar-track {
      background-color: #FCFCFC;
      border-radius: 8px;
    }

    /* This styles the scrollbar thumb (the part you drag) */
    &::-webkit-scrollbar-thumb {
      background-color: #b3b3b3; 
      border-radius: 5px;
    }

    /* This styles the scrollbar itself (including the track and thumb) */
    &::-webkit-scrollbar {
      width: 8px; 
      height: 8px; 
    }
  }

  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #AF1820;
  }
}