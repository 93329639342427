@import "app/assets/css/variables.scss";

.multi-select-search {
  position: relative;
  width: 100%;

  .content-holder {
    display: flex;
    flex-direction: column;
    border: 1px solid #E2E2E2;
    height: 42px;
    border-radius: 8px;
    text-align: left;
    padding-left: 15px;
    line-height: 40px;
    cursor: pointer;

    &.has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
    }

    .selected-holder {
      display: flex;
      gap: $spacing-03;
      flex-wrap: nowrap;
      padding-right: 6px;
      align-items: center;
      justify-content: space-between;

      .selected-placeholder {
        color: #878787;
      }

      span {
        width: 100%;
        height: 42px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .search-toolbar {
        display: flex;

        svg {
          margin-right: 10px;
          color: #7f7f7f;
        }
      }
    }

    &:hover {
      background-color: #FCFCFC;
      box-shadow: 0 0 0 1px #E2E2E2;
    }

    &:focus-visible {
      background-color: #FCFCFC;
      border: 1px solid transparent;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    &.has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
    }
  }

  .search-content {
    position: absolute;
    background: #FCFCFC;
    z-index: 9999;
    border: 1px solid #E2E2E2;
    border-radius: 10px;
    margin-top: 5px;
    padding: 5px;
    width: auto;
    min-width: 200px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);

    .search-bar {
      .input-group {
        border-bottom: 1px solid #E2E2E2;
        border-left: none;
        border-top: none;
        border-right: none;
        border-radius: 0;

        &:hover {
          box-shadow: none !important;
        }
      }
    }

    .search-table {
      display: flex;
      flex-direction: column;
      max-height: 200px;
      overflow-y: scroll;
      line-height: 28px;
      padding-top: 10px;
      padding-right: 6px;
      border-spacing: 4px;
      border-collapse: separate;

      .table-body {
        .table-item {
          border: none;
          cursor: pointer;
          color: #2E2E2E;
          border-radius: $spacing-03;
          overflow: hidden;
          position: relative;
        
          td {
            padding: 6px !important;

            &:hover {
              background-color: #EDEDED;
              border-radius: $spacing-03; 
            }
          
            &.selected {
              background-color: #EDEDED;
              border-radius: $spacing-03; 
            }
          
            &:active {
              background-color: #E0E0E0;
              border-radius: $spacing-03;
            }
          }
        
          td {
            padding-left: 12px !important;
          }
        
          .line-item {
            display: flex;
            align-items: flex-start;
            gap: $spacing-03;
        
            .gooten-checkbox {
              margin-top: 5px;
            }
        
            div {
              word-break: break-word;
            }
          }
        }
        
      }
    }

    .hover-tooltip {
      padding: 10px 5px 5px 15px;
      border-top: 1px solid #E2E2E2;
      height: 40px;
    }
  }
  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #E6474F;
  }
}
