@import "app/assets/css/variables.scss";

.gooten-button-menu {
  
  &.variant-primary {
    .dropdown {
      button {
        color: #fff !important;
        background-color: #171717;
        border-radius: $spacing-03;
        border: none;
  
        &:hover {
          background-color: #2E2E2E !important;
        }
  
        &:active {
          background-color: #595959 !important;
        }
  
        &:disabled {
          background-color: #878787 !important;
          cursor: default;
        }

        &::after {
          display: none;
        }

        svg {
          margin-left: 5px;
          margin-top: -2px;
        }
      }
    }

    &.size-small {
      button {
        padding: $spacing-03 $spacing-04;
        line-height: 24px;
        gap: $spacing-03;
        font-size: 14px;
      }
    }

    &.size-medium {
      button {
        padding: $spacing-04 $spacing-05;
        line-height: 26px;
        gap: $spacing-03;
        font-size: 16px;
      }
    }

    &.size-large {
      button {
        padding: $spacing-05;
        line-height: 30px;
        gap: $spacing-04;
        font-size: 18px;
      }
    }
  
    .dropdown-menu {
      border-radius: $spacing-04;
      font-size: 16px;
      background-color: #FCFCFC;
      border: 1px solid #E2E2E2;

      padding: $spacing-03;
      box-shadow: 3px 4px 3px -3px rgba(23, 23, 23, 0.3);
      min-width: 0;

      .dropdown-item {
        font-size: 16px;
        line-height: 24px;
        padding: $spacing-03;
        border-radius: $spacing-03;
        color: #2E2E2E;

        &.destructive {
          color: #821218;
          &:hover {
            background-color: rgba(218, 30, 40, 0.10);
          }
        }

        &:hover {
          background-color: #EDEDED;
        }
        &:active {
          background-color: #E0E0E0;
        }
        &:disabled {
          color: #B5B5B5;
        }
      }
    }
  }

  &.variant-secondary {
    .dropdown {
      button {
        color: #171717;
        background-color: #fff;
        border-radius: $spacing-03;
        border: 1px solid #171717 !important;

        &:hover {
          background-color: #E0E3E6;
          border: 1px solid #2E2E2E !important;
        }

        &:active {
          color: #595959;
          background-color: #C3CAD0;
          border: 1px solid #171717 !important;
        }

        &:focus-visible {
          outline: none;
          border: none;
          box-shadow: 0 0 0 4px #CBDEFB;
        }
        
        &:disabled {
          color: #595959;
          background-color: #fff;
          border: 1px solid #878787 !important;
          cursor: default;
          opacity: 0.4;
        }

        &::after {
          display: none;
        }

        svg {
          margin-left: 5px;
          margin-top: -2px;
        }
      }
    }

    &.size-small {
      button {
        padding: $spacing-03 $spacing-04;
        line-height: 24px;
        gap: $spacing-03;
        font-size: 14px;
      }
    }

    &.size-medium {
      button {
        padding: $spacing-04 $spacing-05;
        line-height: 26px;
        gap: $spacing-03;
        font-size: 16px;
      }
    }

    &.size-large {
      button {
        padding: $spacing-05;
        line-height: 30px;
        gap: $spacing-04;
        font-size: 18px;
      }
    }
  
    .dropdown-menu {
      border-radius: 8px;
      font-size: 16px;
      background-color: #F2F2F2;
      border: 1px solid #E2E2E2;

      padding: $spacing-02;
      box-shadow: 3px 4px 3px -3px rgba(23, 23, 23, 0.3);
      min-width: 0;

      .dropdown-item {
        font-size: 16px;
        line-height: 24px;
        padding: $spacing-02;
        border-radius: $spacing-03;
        color: #2E2E2E;

        &.destructive {
          color: #821218;
          &:hover {
            background-color: rgba(218, 30, 40, 0.10);
          }
        }

        &:hover {
          background-color: #E2E2E2;
        }
        &:active {
          background-color: #D3D5E4;
        }
        &:disabled {
          color: #B5B5B5;
        }
      }
    }
  }
}