@import "app/assets/css/variables.scss";

.shipment-card {
  &.gooten-card {
    padding: 0px;

    .card-header:not(.collapsed) {
      border-bottom: none;
      padding-bottom: 0px;
    }
  }

  .card-header {
    align-items: flex-start;
    flex-direction: column;
  }

  .card-body {
    display: flex;
    flex-direction: column;
    padding: $spacing-05;
  }

  .shipment-header {
    display: flex;
    flex-direction: column;
    gap: $spacing-02;
    width: 100%;
    background-color: #171717;
    padding: $spacing-05;
    border-radius: $spacing-05 $spacing-05 0 0;

    .send-to-vendor-button {
      background-color: #1d8139;
    }

    .shipment-header-body {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: $spacing-03;

      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;

      .id-status {
        display: flex;
        align-items: center;
        gap: $spacing-04;
        font-size: 20px;
        font-weight: 700;
        line-height: 32px;
        color: #FFF;
      }

      .shipment-data-and-options {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-06;

        .shipment-options {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $spacing-02;
        }
      }
    }

    .additional-shipment-info {
      cursor: pointer;

      .info-field {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-03;
        font-size: 14px;
        font-weight: 400;

        // target the first element
        :first-child {
          color: #B5B5B5;
        }

        // target the last element
        .facility-name {
          color: #FFF;
        }
      }

      .vendor-facility-stats {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        align-items: flex-start;
        row-gap: $spacing-02; 
        column-gap: $spacing-06;
        font-size: 14px;
        font-weight: 400;
        color: #B5B5B5;
  
        .vendor-facility-contact-item {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: $spacing-03;
          height: 100%;
  
          svg {
            color: #FFF;
          }
        }
  
        .vendor-data-loading {
          .loading-animation {
            .spinner-border {
              width: 16px;
              height: 16px;
            }
          }
        }
  
        &.show {
          display: flex;
        }
  
        &.hide {
          display: none;
        }
      }
    }

    .gooten-error-message {
      font-weight: 400;

      .message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-04;
      }
    }

    &.collapsed {
      border-radius: $spacing-05;
    }
  }

  .canceled-items-section {
    margin-top: $spacing-05;
    display: flex;
    flex-direction: column;
    gap: $spacing-04;

    &.hide-header {
      margin-top: 0px;
    }

    .canceled-items-top-bar {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: $spacing-03;
      margin-bottom: $spacing-02;
      border-bottom: 1px solid #ededed;

      .canceled-items-title {
        display: flex;
        align-items: center;
        gap: $spacing-03;
        font-size: 16px;
        font-weight: 600;
        color: #2e2e2e;
  
        svg {
          width: 16px;
          height: 16px;
        }
      }
    }
  }
}