.gooten-tag {
  display: inline-flex;
  align-items: center;
  background-color: rgba(25, 153, 255, 0.10);
  border: 1px solid rgba(25, 153, 255, 0.10);;
  border-radius: 25px;
  padding: 4px 8px;
  gap: 8px;
  font-size: 13px;
  color: #0E50B3;
  line-height: 1;
  height: 24px;

  .tag-label {
    white-space: nowrap;
  }

  .remove-icon {
    cursor: pointer;
    color: #0E50B3;
    transition: color 0.2s ease;
    
    &:hover {
      opacity: 0.8;
    }
  }
}
