@import "app/assets/css/variables.scss";

.gooten-input {
  position: relative;

  label {
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;
    margin-bottom: -$spacing-02;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.disabled {
      color: #B5B5B5;
    }
  }

  input {
    color: #2E2E2E;
    font-size: 16px;
    line-height: 26px;
    border-radius: $spacing-03;
    background-color: #FCFCFC !important;
    border: 1px solid #E2E2E2 !important;
    padding: $spacing-03 $spacing-05;

    &.with-icon {
      padding-right: 2.5rem;
    }
    
    &::placeholder { 
      font-size: 16px; 
      color: #878787;  
      opacity: 1; 
    }

    &:not([readonly]):not(:disabled):focus-visible {
      background-color: #FCFCFC;
      border: 1px solid transparent;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    &:not([readonly]):not(:disabled):hover {
      background-color: #FCFCFC !important;
      box-shadow: 0 0 0 1px #E2E2E2;
    }

    &:disabled {
      background-color: #f7f7f7 !important;
      color: #9c9c9c;
    }

    &.has-error {
      box-shadow: 0 0 0 2px #E6474F !important;
    }
  }

  .input-icon {
    position: absolute;
    top: 44px;
    right: 14px; 
    color: #878787; 
    
    &.clickable {
      cursor: pointer;
    }
  }

  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #E6474F;
  }

  .question-icon {
    position: absolute;
    top: 21px;
    right: -4px;
    color: #7c7c7c;
    cursor: pointer;
    font-size: 16px;
  }
}