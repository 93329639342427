@import "app/assets/css/variables.scss";

.strategy-details {
  display: flex;
  flex-direction: column;
  gap: $spacing-06;
  margin: $spacing-05;

  .merchant-name-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 24px;

    .merchant-name {
      display: flex;
      align-items: center;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }
  }
}