@import "app/assets/css/variables.scss";

.public-integrations {
  .public-integrations-header {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin-bottom: $spacing-04;
  }

  .search-bar {
    margin-bottom: $spacing-05;
  }

  .public-integrations-container {
    display: flex;
    flex-direction: row;
    gap: $spacing-05;

    .integration-cards-container {
      flex: 1;
    }

    .content-categories-card {
      width: 100%;
      max-width: 190px;

      .content-categories {
        display: flex;
        flex-direction: column;
        gap: $spacing-03;
        width: 100%;
        max-width: 190px;
    
        .content-categories-list {
          display: flex;
          flex-direction: column;
          gap: $spacing-03;
          width: 100%;
    
          .content-category {
            display: flex;
            padding: $spacing-04 $spacing-05;
            font-size: 16px;
            font-weight: 700;
            line-height: 26px;
            border-radius: 12px;
            border: 1px solid #E8E8E8;
            white-space: nowrap;
            cursor: pointer;
    
            &:hover {
              background-color: #EDEDED;
            }
    
            &.active {
              background-color: #EDEDED;
            }
          }
    
          @media (max-width: 780px) {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
            gap: $spacing-05;
            width: 100%;
          }
        }
    
        @media (max-width: 780px) {
        max-width: 100%;
        }
      }
    }
  
    .public-integrations-content {
      display: flex;
      flex-direction: row;
      gap: $spacing-05;
  
      .content-list {
        display: flex;
        flex-wrap: wrap;
        gap: $spacing-05;
        width: 100%;

        > * {
          flex: 1 1 250px;
          min-width: 250px;
        }
      }
  
      .gooten-error-message {
        height: 40px;
      }
  
      @media (max-width: 780px) {
        flex-direction: column;

        .content-list > * {
          max-width: 100%;
        }
      }
    }
  
    .no-integrations-found {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: $spacing-05;
      border-radius: $spacing-05;
      border: 1px solid #E8E8E8;
      background: #FCFCFC;
      box-shadow: 0px 3px 7px 1px rgba(23, 22, 22, 0.1);
      height: 200px;
      width: 100%;
    }
  }
}
