@import "app/assets/css/variables.scss";

.webhook-customize {
  margin: $spacing-05;

  .webhook-customize-header {
    display : flex;
    justify-content : space-between;
    font-size : 32px;
    font-weight : 600;
    letter-spacing: 0.5px;
    margin-bottom: $spacing-05;

    .webhook-name {
      display: flex;
      flex-direction: row;
      gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-05;
    }
  }

  .webhook-customize-details {
    margin-bottom: $spacing-05;
  }

  .test-snippet-body {
    display: flex;
    gap: $spacing-05;
    align-items: center;

    .test-snippet-code {
      flex: 1;
    }

    .code-snippet-output>textarea {
      transition: box-shadow 0.5s ease-out;
      box-shadow: none;
    }

    .highlight-animation-success>textarea {
      box-shadow: 0 0 8px 2px #6fe7a0;
    }

    .highlight-animation-error>textarea {
      box-shadow: 0 0 8px 2px #E6474F;
    }
  }

  .code-snippet {
    margin-bottom: 0px;

    border: 1px solid #E8E8E8;
    border-radius: 8px;

    .overflow-guard,
    .monaco-editor {
      border-radius: 8px;
    }
  }

  .code-snippet-output {
    textarea {
      height: 250px;
    }
  }
}