.product-card {
  width: 100%;
  max-height: fit-content;
  border-radius: 8px; 
  border: none;
  transition: filter 0.20s ease-in-out;
  cursor: pointer;
  box-shadow: inset 0px 0px 0px 1px #E8E8E8;

  &:hover {
    box-shadow: inset 0px 0px 0px 2px #E8E8E8;

    svg {
      display: block;
    }
  }

  &.selected {
    background-color: #a6e6ff;
    border: 2px solid #3c7391;
    margin: -1px;
  }

  svg {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    top: 0px;
    right: 0px;
    
    display: none;

    &.red-fill {
      color: #DA1E28;
    }

    &.green-fill {
      color: #2E2E2E;
    }
  }

  .item-image {
    position: relative;
    margin: 10px;

    display: flex;
    justify-content: center;
    align-items: center;
    height: 125px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border-radius: 8px;
    }
  }

  .item-data {
    margin: 10px;
    color: #2E2E2E;

    .item-quantity,
    .item-id {
      display: flex;
      justify-content: space-between;
    }
  }
}