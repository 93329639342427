@import "app/assets/css/variables.scss";

.shipping-address-card {
  .card-header {
    height: 39px;
  }

  .form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 0px 20px;
  
    .form-col {
      flex: 1;
    }
  
    @media (max-width: 1600px) {
      flex-direction: column;
    }
  }
  
  .data-value {
    font-size: 16px;
    line-height: 26px;
    word-wrap: break-word;
  }
  .pencil-icon {
    cursor: pointer;
  }
  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    
    .btn-primary {
      margin-left: 8px;
    }
  }
}