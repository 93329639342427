@import "app/assets/css/variables.scss";

.role-view {
    padding: $spacing-05;

    .roles-view-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 32px;
        font-weight: 600;
        padding-bottom: $spacing-05;

        .role-name {
            display: flex;
            flex-direction: row;
            gap: $spacing-04;
            font-size: 32px;
            font-weight: 600;
        }
    }

    .gooten-table {
        td {
            vertical-align: top;
        }
        
        .default-role-checkbox {
            text-align: center;

            .gooten-checkbox-wrapper {
                height: 32px;
            }
            
            input[type="checkbox"] {
                cursor: pointer;
                width: 16px;
                height: 16px;
                
                &:disabled {
                    cursor: not-allowed;
                    opacity: 0.5;
                }
            }
        }

        .role-actions-menu {
            text-align: center;
            > * {
                position: relative;
                left: -6px;
            }
        }
        
        .default-role-checkbox {
            .gooten-checkbox {
                margin: 0 auto 0 calc(50% - 6px);
                transform: translateX(-50%);
            }
        }
    }

    .no-matching-results {
        text-align: center;
        width: 100%;
        padding: 0 40px;

        svg {
            font-size: 18px;
            margin: -6px 3px 0 0;
        }
    }

    .role-load-failed {
        width: 100%;
        text-align: center;
        padding: 50px 0;

        svg {
            font-size: 18px;
            margin: -6px 3px 0 0;
        }
    }
}