@import "app/assets/css/variables.scss";

.gooten-error-message {
  display: flex;
  align-items: center;
  gap: $spacing-04;
  padding: $spacing-03 $spacing-05;
  border-radius: $spacing-03;
  width: 100%;

  svg {
    width: 16px;
    height: 16px;
  }

  .message {
    font-size: 16px;
    width: 100%;
  }
}
