@import "app/assets/css/variables.scss";

.icon-data {
  .icon-data-value {
    display: flex;
    align-items: center;
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;

    &.dark-mode {
      color: #E2E2E2;
    }

    &.allow-copy {
      cursor: pointer;

      .text-content {
        display: flex;
        align-items: flex-start;
        gap: $spacing-03;

        &:hover + .data-point-icon svg {
          visibility: visible;
        }
      }
    }

    &.allow-overflow {
      .text-content  {
        white-space: initial;
        overflow: initial;
        text-overflow: initial;

        .label {
          word-break: break-all;
        }
      }
    }

    .text-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      display: flex;
      align-items: flex-start;
      gap: $spacing-03;

      &.clickable {
        color: #3982EF;
        cursor: pointer;
      
        &:hover {
          text-decoration: underline;
        }

        &.dark-mode {
          color: #70A5F5;
        }
      }

      svg {
        fill: #878787;
        stroke: initial; 
        width: 16px;
        height: 16px;
        margin-top: -6px;
      }

      &.dark-mode svg {
        fill: #E2E2E2;
      }
    }

    .data-point-icon svg {
      visibility: hidden;
      margin-top: -9px;
      font-size: 16px;
      margin-left: $spacing-04;
    }
  }
}
