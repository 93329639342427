@import "app/assets/css/variables.scss";

.order-item {
  display: flex;
  flex-direction: column;
  border-radius: $spacing-04;

  .item-header {
    display: flex;
    justify-content: space-between;
    gap: $spacing-05;
    padding: $spacing-03 $spacing-04;
    border-radius: $spacing-04 $spacing-04 0px 0px;
    border-top: 1px solid #B5B5B5;
    border-right: 1px solid #B5B5B5;
    border-left: 1px solid #B5B5B5;
    background: #E2E2E2;

    .id-status {
      display: flex;
      flex-direction: row;
      gap: $spacing-05;
      align-items: center;

      .item-name {
        color: #2E2E2E;
        font-size: 16px;
      }
    }
  }

  .item-body {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;
    
    border-radius: 0px 0px $spacing-04 $spacing-04;
    border-bottom: 1px solid #B5B5B5;
    border-right: 1px solid #B5B5B5;
    border-left: 1px solid #B5B5B5;
    background: #FCFCFC;
    padding: $spacing-04;
    font-size: 14px;

    .item-details {
      .item-info-and-pricing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: $spacing-05;

        .item-info {
          display: grid;
          grid-template-columns: max-content 1fr;
          gap: $spacing-02 $spacing-04;

          .item-row {
            display: contents;

            .item-label {
              grid-column: 1;
              color: #595959;
            }

            .item-value {
              grid-column: 2;
            }
          }
        }

        .item-pricing {
          display: grid;
          grid-template-columns: repeat(3, auto);
          gap: $spacing-02 $spacing-04;
          text-align: right;

          .pricing-header {
            display: contents;

            .price-label {
              color: #595959;
            }
          }

          .pricing-row {
            display: contents;
          }
        }

        @media (max-width: 1000px) {
          flex-direction: column !important;
          align-items: flex-start;

          .item-pricing {
            text-align: left;
          }
        }
      }
    }

    .image-error-message-bar {
      .message {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-02;
      }
    }
  }
}