@import "app/assets/css/variables.scss";

.tracking-info-modal {
  .gooten-modal-content {
    width: 100%;
    max-width: 450px;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .tracking-info {
    .details-row {
      display: flex;
      flex-direction: row;
      margin-bottom: $spacing-03;

      .details-label {
        color: #595959;
        width: 130px;
        min-width: 130px;
      }
      
      .details-data {
        color: rgb(33, 37, 41);

        word-break: break-all;
        white-space: normal;
      }

      .tracking-number {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-03;
        cursor: pointer;
      }
    }
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;
  }
}
