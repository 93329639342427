@import "app/assets/css/variables.scss";

.refund-details {
  margin: $spacing-05;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .refund-details-header {
    margin-bottom: $spacing-05;
    width: 100%;
  }

  .order-num-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;
    flex-wrap: wrap;
    gap: $spacing-04;

    .order-num-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 0px;
      column-gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;

      .order-num {
        white-space: nowrap;
      }
    }
  }

  .extra-details-container {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    gap: $spacing-06;
    font-size: 16px;
    line-height: 26px;
    color: #2E2E2E;
  }

  .gooten-modal .gooten-modal-content {
    max-width: 650px;
  }
}
