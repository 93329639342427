@import "app/assets/css/variables.scss";

.gooten-button {
  display: flex;
  align-items: center;
  justify-content: center;

  .label {
    white-space: nowrap;
  }

  &.variant-primary {
    color: #fff;
    background-color: #171717;
    border-radius: $spacing-03;
    border: none;

    &.size-tiny {
      padding: $spacing-02 $spacing-03;
      line-height: 20px;
      gap: $spacing-03;
      font-size: 12px;
    }

    &.size-small {
      padding: $spacing-03 $spacing-04;
      line-height: 24px;
      gap: $spacing-03;
      font-size: 14px;
      height: 40px;
    }

    &.size-medium {
      padding: $spacing-04 $spacing-05;
      line-height: 26px;
      gap: $spacing-03;
      font-size: 16px;
      height: 50px;
    }

    &.size-large {
      padding: $spacing-05;
      line-height: 30px;
      gap: $spacing-04;
      font-size: 18px;
      height: 62px;
    }

    &:hover {
      background-color: #2E2E2E;
    }

    &:active {
      background-color: #595959;
    }

    &:focus-visible {
      outline: none;
      border: none;
      box-shadow: 0 0 0 4px #CBDEFB;
      background-color: #171717;
    }

    &:disabled {
      background-color: #878787;
      cursor: default;
    }

    &.destructive {
      background-color: #AF1820;

      &:hover {
        background-color: #821218; 
      }
      &:active {
        background-color: #550C0F; 
      }
      &:focus-visible {
        background-color: #AF1820;
      }
      &:disabled {
        background-color: #878787;
      }
    }
  }

  &.variant-secondary {
    color: #171717;
    background-color: #fff;
    border-radius: $spacing-03;
    border: 1px solid #171717;

    &.size-tiny {
      padding: $spacing-02 $spacing-03;
      line-height: 20px;
      gap: $spacing-03;
      font-size: 12px;
    }

    &.size-small {
      padding: $spacing-03 $spacing-04;
      line-height: 24px;
      gap: $spacing-03;
      font-size: 14px;
      height: 40px;
    }

    &.size-medium {
      padding: $spacing-04 $spacing-05;
      line-height: 26px;
      gap: $spacing-03;
      font-size: 16px;
      height: 50px;
    }

    &.size-large {
      padding: $spacing-05;
      line-height: 30px;
      gap: $spacing-04;
      font-size: 18px;
      height: 62px;
    }

    &:hover {
      background-color: #E0E3E6;
      border: 1px solid #2E2E2E;
    }

    &:active {
      color: #595959;
      background-color: #C3CAD0;
      border: 1px solid #171717;
    }

    &:focus-visible {
      outline: none;
      border: none;
      box-shadow: 0 0 0 4px #CBDEFB;
    }
    
    &:disabled {
      color: #595959;
      background-color: #fff;
      border: 1px solid #878787;
      cursor: default;
      opacity: 0.4;
    }

    &.destructive {
      border: 1px solid #AF1820;
      color: #AF1820;

      &:hover {
        background-color: rgba(218, 30, 40, 0.10);
      }
      &:active {
        background-color: rgba(218, 30, 40, 0.10);
        color: #821218;
      }
      &:focus-visible {
        background-color: #AF1820;
      }
      &:disabled {
        color: #821218;
      }
    }
  }
}