@import "app/assets/css/variables.scss";

.history-view {
  .history-description {
    max-width: 300px;
    white-space: wrap;
    word-wrap: break-word;
  }

  .entity-type-dropdown {
    max-width: 250px;
  }
}