@import "app/assets/css/variables.scss";

.connect-variables {
  margin: $spacing-05;

  .connect-variables-header {
    display : flex;
    justify-content : space-between;
    font-size : 32px;
    font-weight : 600;
    letter-spacing: 0.5px;
    margin-bottom: $spacing-05;

    .header-name {
      display: flex;
      flex-direction: row;
      gap: $spacing-04;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-05;
    }
  }

  .connect-variables-card {
    padding-bottom: 0px;

    .connect-variables-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: $spacing-05;
    }
  }

  .no-variables {
    padding-bottom: $spacing-05;
  }
}