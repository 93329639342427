@import "app/assets/css/variables.scss";

.exports-list {
  .exports-container {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;

    .exports-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 32px;
      font-weight: 600;
      padding-bottom: $spacing-05;
      height: 66px;
    }

    .search-bar-and-filters {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .search-bar {
        width: 100%;
        margin-bottom: 0px;;
      }
    }

    .preview-error-message {
      .message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }

    .export-item-container {
      display: flex;
      flex-direction: column;
      padding-top: 0;
      padding-bottom: 0;
      
      .export-item {
        padding: $spacing-04 0;

        &:not(:last-child) {
          border-bottom: 1px solid #E5E5E5;
        }
      }
    }

    .gooten-pagination {
      margin-bottom: $spacing-05;   
    }

    .error-message {
      text-align: center;
      padding: 30px !important;

      svg {
        margin: -6px 3px 0 0;
      }
    }
  }
}