@import "app/assets/css/variables.scss";

.refund-history-view {
  .history-description {
    max-width: 300px;
    white-space: wrap;
    word-wrap: break-word;
  }

  .history-data-empty,
  .history-data-failed {
    text-align: center;
    padding: 50px !important;

    svg {
      margin: -6px 3px 0 0;
    }
  }
}