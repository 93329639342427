@import "app/assets/css/variables.scss";

.export-details {
  padding: $spacing-05;

  .export-details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacing-05;
    padding: $spacing-05;

    .title-status-container {
      display: flex;
      flex-direction: row;
      gap: 24px;
    }

    .export-name {
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 24px;
      font-weight: 600;

      .title-display {
        display: flex;
        align-items: center;
        gap: 8px;

        .edit-title-button {
          opacity: 0.6;
          font-size: 14px;
          &:hover {
            opacity: 1;
          }
        }
      }

      .title-edit-container {
        display: flex;
        align-items: center;
        gap: 8px;

        .title-edit-input {
          min-width: 200px;
          
          input {
            font-size: 24px;
            font-weight: 600;
            padding: 4px 8px;
            height: auto;
          }
        }

        .title-action-button {
          font-size: 16px;
          &:hover {
            opacity: 0.8;
          }
        }
      }
    }

    .export-status {
      display: flex;
      align-items: center;
      gap: 16px;
    }

    .action-buttons {
      display: flex;
      gap: 8px;
      justify-content: flex-end;
    }

    .status-chip {
      .status-icon-left {
        svg {
          animation: spin 1s linear infinite;
        }
      }
      .status-icon-right {
        svg {
          margin-left: $spacing-02;
        }
        .spin {
          transform-origin: center;
          animation: spin 1s linear infinite;
        }
      }
    }
  }
  

  .cards-container {
    display: flex;
    margin-bottom: 20px;
    height: calc(100vh - 210px);

    .section-title {
      font-size: 18px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  .data-selection {
    min-width: 250px;
    width: fit-content;

    .selection-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      
      span {
        color: #878787;
        font-size: 14px;
      }
      
      .gooten-button {
        min-width: auto;
      }
    }

    .search-box {
      margin: 10px 0 12px;
    }

    .column-tree {
      height: calc(100vh - 364px);
      overflow-y: auto;
      padding-right: 10px;

      // Scrollbar styling
      &::-webkit-scrollbar-track {
        background-color: #F2F2F2;
        border-radius: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: #b3b3b3;
        border-radius: 4px;
      }

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      .tree-node {
        margin-bottom: 8px;

        .node-content {
          display: flex;
          align-items: center;
          gap: 8px;

          .expand-button {
            padding: 2px;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 20px;
            
            &:hover {
              background-color: rgba(0, 0, 0, 0.05);
              border-radius: 4px;
            }
          }

          .node-label {
            font-weight: 500;
            cursor: default;
          }
        }

        .node-children {
          margin-top: 8px;
        }
      }
    }
  }

  .data-preview {
    flex: 1;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    
    .section-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      .download-preview-button {
        font-size: 14px;
      }
    }

    .preview-table {
      position: relative;
      flex: 1;
      overflow: hidden;
      position: relative;
      
      .gooten-table {
        width: 100%;
        
        th {
          min-width: 150px;
          
          .column-header {
            white-space: nowrap;
            
            .header-first-word {
              font-size: 16px;
              font-weight: 500;
            }

            .header-remaining {
              font-size: 12px;
              color: #9e9e9e;
              margin-left: 5px;
            }
          }
        }
        
        td {
          min-width: 150px;
          white-space: nowrap;
        }
      }
      
      // Create a scrollable container
      .table-scroll-container {
        overflow-x: auto;
        overflow-y: auto;
        height: 100%;
        width: 100%;

        tr {
          height: 43.5px;
        }

        // Scrollbar styling
        &::-webkit-scrollbar-track {
          background-color: #F2F2F2;
          border-radius: 8px;
        }

        &::-webkit-scrollbar-thumb {
          background-color: #b3b3b3;
          border-radius: 4px;
        }

        &::-webkit-scrollbar {
          width: 8px;
          height: 8px;
        }
      }
    }

    .section-info {
      font-size: 14px;
      margin: -6px 0 9px 0;
    }

    .no-results-message {
      text-align: center;
      padding: 20px;
      color: #666;
      font-style: italic;
      height: 150px;
    }
    
    .no-columns-message {
      padding-top: 60px;
      text-align: center;
      color: #666;
    }

    .preview-error-message {
      .message {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

