@import "app/assets/css/variables.scss";

.delete-export-modal {
  .gooten-modal-content {
    width: 100%;
    max-width: 550px;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 8px;
    margin-top: 16px;
  }
}