@import "app/assets/css/variables.scss";

.merchant-templates-view {
  display: flex;
  flex-direction: column;
  gap: $spacing-05;

  .card-body {
    height: 100px;
  }

  .table-body {
    .template-row {
      .template-actions-menu {
        text-align: center;
      }
    }
  }
}