@import "app/assets/css/variables.scss";

.webhook-details {
  margin: $spacing-05;

  .webhook-details-header {
    display : flex;
    justify-content : space-between;
    font-size : 32px;
    font-weight : 600;
    letter-spacing: 0.5px;
    margin-bottom: $spacing-06;

    .webhook-name {
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 32px;
      font-weight: 600;
    }

    .action-buttons {
      display: flex;
      align-items: center;
      gap: $spacing-05;
    }
  }

  .webhook-details-card {
    &.webhook-edit-mode {
      padding-bottom: 0px;
    }
  }

  .event-table-section {
    .event-table-header {
      color: #2E2E2E;
      font-size: 18px;
      font-weight: 700;
      line-height: 30px;
    }

    .no-events {
      text-align: center;
      padding: $spacing-06 !important;

      svg {
        margin-right: 5px;
        margin-top: -2px;
      }
    }
  }

  .edit-mode {
    .vendor-merchant-selector {
      display: flex;
      flex-direction: row;
      gap: $spacing-05;

      >div {
        max-width: 300px;
      }
    }

    .add-event-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: $spacing-05;

      .gooten-dropdown {
        width: 100%;
        max-width: 300px;
      }

      .gooten-button {
        margin-top: 10px;
      }    
    }

    .api-request-details-section {
      margin-top: $spacing-05;

      .callback-info {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $spacing-05;

        .gooten-dropdown {
          width: 100%;
          max-width: 125px;
        }

        .gooten-input {
          width: 100%;
        }
      }
    }

    .header-info-section {
      .header-row {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: $spacing-05;

        .custom-header,
        .custom-value {
          width: 100%;
          max-width: 350px;
        }

        .trash-can {
          width: 80px;

          .gooten-button-icon {
            padding-bottom: 18px;
          }
        }
      }
    }
  }

  .view-mode {
    display: flex;
    flex-direction: column;
    gap: $spacing-05;

    .view-callback-info {
      display: flex;
      flex-direction: row;
      gap: $spacing-07;
    }

    .view-header-info {
      .view-header-table {
        // target the 2nd td
        td:nth-child(2) {
          padding-left: $spacing-07;
        }
        

        .header-title {
          font-size: 16px;
          font-weight: 400;
          color: #878787;
        }

        .header-value {
          padding-left: 10px;
        }
      }
    }
  }

  .data-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
      margin-top: -3px;
    }
  }

  .gooten-error-message {
    margin: $spacing-05 0;
  }
}