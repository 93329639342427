@import "app/assets/css/variables.scss";

.gooten-multi-select {
  .line-item {
    display: flex;
    align-items: center;
    gap: $spacing-03;
  }
  
  >label {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    margin-bottom: 4px;
    color: #2E2E2E;
  }

  &.disabled {
    .dropdown-container {
      background-color: #dfdfdf !important;
      color: #B5B5B5;
    }
  }

  .dropdown-heading {
    .dropdown-heading-value {
      span {
        font-family: 'OpenSans';
      }
    }
  }

  // add spacing to the very top and very bottom items
  .rmsc .options {
    >label:first-child {
      margin-top: $spacing-03 !important;
    }
    >li:last-child {
      label {
        margin-bottom: $spacing-03 !important;
      }
    }
  }

  .rmsc .select-item {
    font-size: 16px;
    border-radius: $spacing-03;
    margin: $spacing-02 $spacing-03;
    padding: $spacing-03;

    &.selected {
      background-color: #EDEDED;
    }
  }

  .rmsc .options {
    background-color: #FCFCFC;

    .option:first-child label {
      margin-top: $spacing-03 !important;
    }
  }

  .rmsc .select-item:hover {
    background-color: #EDEDED;
  }

  .dropdown-container {
    color: #2E2E2E;
    font-size: 16px;
    line-height: 24px;
    border-radius: $spacing-03;
    background-color: #FCFCFC !important;
    border: 1px solid #E2E2E2 !important;
    padding: 1px;
    height: 44px;
    box-shadow: none !important;

    &::placeholder { 
      font-size: 16px; 
      color: #878787;  
      opacity: 1; 
    }

    &:not([readonly]):not(:disabled):focus-visible {
      background-color: #FCFCFC;
      border: 1px solid transparent;
      box-shadow: 0 0 0 4px #CBDEFB !important;
    }

    &:not([readonly]):not(:disabled):hover {
      background-color: #FCFCFC;
      box-shadow: 0 0 0 1px #E2E2E2 !important;
    }

    &:disabled {
      background-color: #E2E2E2;
      color: #B5B5B5;
    }
  }

  &:not([readonly]):not(:disabled).has-error {
    .dropdown-container {
      box-shadow: 0 0 0 2px #E6474F !important;
      border: 1px solid transparent !important;
    }
  }

  .dropdown-content {
    .panel-content {
      border-radius: $spacing-03;

      .options {
        /* This styles the scrollbar track (the part the thumb moves along) */
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }

        /* This styles the scrollbar thumb (the part you drag) */
        &::-webkit-scrollbar-thumb {
          background-color: #b3b3b3; 
          border-radius: 4px;
        }

        /* This styles the scrollbar itself (including the track and thumb) */
        &::-webkit-scrollbar {
          width: 8px; 
          height: 8px; 
        }
      }
    }
  }

  .error {
    border: none;
    font-size: 12px;
    line-height: 20px;
    height: 20px;
    letter-spacing: 0.5px;
    color: #AF1820;
  }
}