@import "app/assets/css/variables.scss";

.export-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  
  .left-side {
    display: flex;
    flex-direction: column;
    flex: 1;

    .export-title {
      font-size: 18px;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .created-by {
      font-size: 14px;
      font-weight: 400;
      color: #595959;
    }

    @media (max-width: 1500px) {
      width: initial;
    }
  }

  .created-date {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    color: #595959;
    width: 300px;
  }

  .right-side {
    flex: 1;
    justify-content: flex-end;
    display: flex;
    flex-direction: row;
    gap: $spacing-03;
    align-items: center;

    .button-icon {
      transition: transform 0.2s ease-in-out;
    }

    .status-icon-left {
      svg {
        transform-origin: center;
        animation: spin 1s linear infinite;
      }
    }

    .status-icon-right {
      svg {
        margin-left: $spacing-02;
      }
      .spin {
        transform-origin: center;
        animation: spin 1s linear infinite;
      }
    }
  }

  .spin {
    svg {
      transform-origin: center;
      animation: spin 1s linear infinite;
    }
  }

  .latest-run {
    padding: $spacing-05;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $spacing-05;

    .run-info {
      display: flex;
      flex-direction: column;
      gap: $spacing-04;
      flex-grow: 1;

      .run-detail {
        display: flex;
        align-items: center;
        gap: $spacing-04;

        label {
          font-size: 14px;
          font-weight: 600;
          color: #595959;
          min-width: 100px;
        }

        span {
          font-size: 14px;
          color: #333333;
        }
      }
    }

    .download-action {
      &.spin {
        svg {
          transform-origin: center;
          animation: spin 1s linear infinite;
        }
      }
    }

    .history-loading {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: $spacing-03;
      padding: $spacing-03;
      color: #595959;

      .loading-animation {
        .spinner-border {
          width: 16px;
          height: 16px;
        }
      }
    }

    .no-history, .error-message {
      width: 100%;
      text-align: center;
      color: #595959;
      font-size: 14px;
      padding: $spacing-03;

      svg {
        margin-right: $spacing-02;
      }
    }

    .error-message {
      color: #ff4444;
    }
  }

  &.highlight-row {
    animation: glowHighlight 2.5s cubic-bezier(0.4, 0, 0.2, 1);
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes glowHighlight {
    0% {
      background-color: transparent;
      box-shadow: none;
    }
    15%, 85% {
      background-color: rgba(255, 255, 180, 0.5);
      box-shadow: 0 0 10px rgba(255, 255, 180, 0.5);
    }
    100% {
      background-color: transparent;
      box-shadow: none;
    }
  }
}

