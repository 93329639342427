@import "app/assets/css/variables.scss";

.webhook-logs {
  margin-top: $spacing-06;

  .actions {
    min-width: 110px;
  }

  .no-results {
    text-align: center;
    height: 100px;
    vertical-align: middle !important;
  }

  .history-row {
    .webhook-status {
      .status-chip {
        margin: 0 auto;
      }
    }
  }
}