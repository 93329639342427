@import "app/assets/css/variables.scss";

.edit-quantity-modal {
  .edit-quantity-modal-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    color: #2E2E2E;
    padding-bottom: $spacing-05;
    border-bottom: 1px solid #E8E8E8;
  }

  .edit-quantity-modal-loader {
    height: 239px;
  }

  .edit-quantity-modal-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: $spacing-05;
    padding-top: $spacing-05;
  }

  .gooten-modal-body {
    padding-bottom: 0 !important;
  }

  .action-buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: $spacing-04;
  }

  // don't show the stepper arrows for the number field
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
  }

  // don't show for firefox as well
  input[type="number"] {
      -moz-appearance: textfield;
  }
}

