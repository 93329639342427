@import "app/assets/css/variables.scss";

.product-selector {
  .empty-header .accordion-button::after {
    display: none;
  }

  .product-display {
    width: 100%;
  }

  .product-selector-body {
    display: flex;
    gap: 16px;

    .no-matching-products {
      white-space: nowrap;
    }

    .accordion {
      width: 100%;
      max-width: 260px;

      .accordion-item {
        .order-row {
          cursor: pointer;

          &:hover {
            background-color: #e7f1ff;
          }

          &:last-child td {
            border-style: none;
          }
        }
        button {
          &:focus {
            outline: none;
            box-shadow: none;
          }

        }
        &:first-of-type {
          border-radius: 12px 12px 0 0;
          button {
            border-radius: 12px 12px 0 0;
          }
        }
        &:last-of-type {
          border-radius: 0 0 12px 12px;
          button {
            border-radius: 0 0 12px 12px;
          }
        }

        .accordion-button:not(.collapsed) {
          background-color: #EDEDED;
        }
      }

      .selected-category {
        border: 0;
        box-shadow: none;
        background-color: #EDEDED;
        border-radius: 12px;

        .accordion-button {
          background-color: #EDEDED;
        }

        button {
          color: #2E2E2E;
          border: 0;
          box-shadow: none;
          background-color: #EDEDED;
        }
      }
    }

    .product-container {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
      gap: $spacing-03;
      width: 100%;
    }

    .category-container {
      width: 100%;
      padding-top: 25px;

      min-width: 100px;
      flex: 1 1 100px;

      .category-title {
        font-size: 18px;
        font-weight: 700;
        line-height: 30px;
        padding-bottom: 5px;
      }

      &:first-child {
        padding-top: 0px;
      }
    }
  }
}
