@import "app/assets/css/variables.scss";

.upload-dataset-csv {
    padding: $spacing-05;

    .upload-dataset-csv-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: 32px;
        font-weight: 600;
        padding-bottom: $spacing-05;

        .upload-dataset-csv-title {
            display: flex;
            flex-direction: row;
            gap: 12px;
            font-size: 32px;
            font-weight: 600;
        }
    }

    .dataset-table {
        margin-top: $spacing-10;

        .dataset-title {
            display: flex;
            align-items: baseline;

            .gooten-input {
                width: 25%;
                margin-left: 20px;
            }
        }

        .table-body {
            .dataset-row {
            }

            .source-type {
                width: 20%;
            }

            .use-for {
                width: 25%; 
            }

            .map-to {
                width: 20%;
            }

            .default-value {
                width: 15%;
            }
        }
    }

    .dataset-content-holder {
        height: 70vh;
    }

    .upload-container {
        max-width: 700px;
        max-height: 300px;
        min-width: 70%;
        min-height: 30%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 6px;
        background-color: #ffffff;
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='10' ry='10' stroke='%23E2E2E2FF' stroke-width='5' stroke-dasharray='12%2c15' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
        border-radius: 10px;
        padding: 40px;
        margin: 40px;

        .upload-icon {
            font-size: 26px;
            color: #1969E0;
        }

        .drag-text {
            font-size: 20px;
            font-weight: 600;
        }

        .gray-text {
            color: #878787;
        }
    }
}