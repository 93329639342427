@import "app/assets/css/variables.scss";

.history-and-notes-view {
  .large-screens {
    display: block;
  }

  .history-and-notes-cards {
    display: flex;
    flex-direction: column;
    gap: $spacing-06;
  }

  .small-screens {
    display: none;
  }

  .data-load-failed {
    width: 100%;
    text-align: center;
    padding: 50px 0;

    svg {  
      font-size: 18px;
      margin-right: 6px;
    }
  }

  @media (max-width: 992px) {
    .large-screens {
      display: none;
    }
    .small-screens {
      display: block;
    }
  }
}