@import "app/assets/css/variables.scss";

.data-point {
  &.mask-data {
    cursor: pointer;
  }

  .data-header {
    font-size: 16px;
    line-height: 26px;
    color: #878787;
  }

  .data-value {
    display: flex;
    align-items: center;
    font-size: 16px;
    height: 26px;
    color: #2E2E2E;

    &.allow-copy {
      cursor: pointer;

      .text-content {
        &:hover + .data-point-clipboard-icon svg {
          visibility: visible;
        }
      }
    }

    .text-content {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 26px;
    }

    .data-point-clipboard-icon svg {
      visibility: hidden;
      margin-top: -9px;
      font-size: 16px;
      margin-left: $spacing-04;
    }

    .data-point-mask-icon {
      svg {
      margin: -10px 0 0 6px;      
      }
    }
  }

  .data-value .masked-data {
    display: inline-block;
    line-height: 26px; 
    vertical-align: middle;
  }
}
